var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { fetchCallback, getData } from '../../bus';
import { shortEventDate } from '../../formats';
import { buttonStyles } from '../styles';
export const TransferDialogTag = 'transfer-dialog';
let TransferDialog = class TransferDialog extends LitElement {
    constructor() {
        super(...arguments);
        this.isHidden = true;
        this.eventId = 0;
        this.toEventId = 0;
        this.toPriceId = 0;
        this.events = [];
        this.prices = [];
    }
    render() {
        const eventList = this.events.map((event) => {
            if (this.eventId == event.id) {
                return;
            }
            const eventDate = shortEventDate(event.startDateTime);
            return html `
				<option value="${event.id}" title="${eventDate} - ${event.name}">
					${eventDate} - ${event.name}
				</option>
			`;
        });
        return html `
			<div class="container">
				<div class="header">TRANSFER ORDER</div>
				<div class="body">
					<label for="event">New event</label>
					<select id="event" @change=${this.handleEventChange}>
						<option value="0"></option>
						${eventList}
					</select>
		
					<label for="ticketType">Ticket type</label>
					<select id="ticketType" @change=${this.handlePriceChange}>
						<option value="0"></option>
						${this.prices.map((price) => html `
						<option value="${price.id}">${price.name}</option>
						`)}
					</select>
				
					<label for="note">Notes</label>
					<textarea id="note" name="note" rows="4" cols="50"></textarea>
				</div>
				<div class="footer">
					<button class="cancel" @click=${this.handleCancelClick}>Cancel</button>
					<button @click=${this.handleSubmitClick}>Transfer</button>
				</div>
			</div>
		`;
    }
    willUpdate(changedProperties) {
        if (changedProperties.has('isHidden') && !this.isHidden) {
            return this.getEvents();
        }
    }
    updated(changedProperties) {
        if (changedProperties.has('toEventId') && this.toEventId > 0) {
            return this.getPrices();
        }
    }
    handleEventChange(event) {
        const changedSelect = event.target;
        this.toEventId = parseInt(changedSelect.value);
    }
    handlePriceChange(event) {
        const changedSelect = event.target;
        this.toPriceId = parseInt(changedSelect.value);
    }
    handleCancelClick() {
        this.isHidden = true;
    }
    handleSubmitClick() {
        if (this.toEventId === 0 || this.toPriceId === 0) {
            return alert('Please select event and ticket type');
        }
        if (!this.note.value) {
            return alert('Please enter note');
        }
        const detail = {
            eventId: this.toEventId,
            priceId: this.toPriceId,
            note: this.note.value
        };
        if (this.toPriceId === -1) {
            this.isHidden = true;
            return this.dispatchEventUp('onAseToAseTransfer', detail);
        }
        this.isHidden = true;
        this.dispatchEventUp('onAseToGaTransfer', detail);
    }
    async getEvents() {
        const params = {
            current: true,
            limit: 300
        };
        const responseJson = await getData('/ase3/actions/events', params);
        if (!fetchCallback(responseJson)) {
            return false;
        }
        this.events = responseJson.items;
    }
    async getPrices() {
        const prices = [];
        const params = {
            limit: 100
        };
        const responseJson = await getData(`/ase3/actions/events/${this.toEventId}/prices`, params);
        let isAse3 = false;
        if (!fetchCallback(responseJson)) {
            return false;
        }
        responseJson.items.forEach((price) => {
            if (price.priceLevelId > 0) {
                isAse3 = true;
                return;
            }
            prices.push(price);
        });
        if (isAse3) {
            prices.unshift({
                id: -1,
                name: '*** SELECT SEATS ON MAP ***',
                priceLevelId: -1
            });
        }
        this.prices = prices;
    }
    dispatchEventUp(eventName, detail = {}) {
        this.dispatchEvent(new CustomEvent(eventName, {
            detail,
            bubbles: true,
            composed: true
        }));
    }
};
TransferDialog.styles = [
    buttonStyles,
    css `
		:host {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.4);
			z-index: 100;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius:5px;
			font-family: Poppins;
		}

		:host([ishidden]){
			display: none;
		}

		.container{
			background: var(--color-main-background);
			box-shadow: 0px 1px 9.4px 0.6px rgba(154, 154, 154, 0.24);
			border-radius: 5px;
			width: 680px;
			max-height: 90%;
			display:flex;
			flex-direction:column;
		}

		.header {
			padding: 10px 35px 5px 35px;
			text-align: center;
  			font-family: Poppins;
			font-weight: bold;
			font-size: 16px;
			color: var(--color-title);
			border-bottom: 2px solid #E8E9EB;
		}

		.body {
			padding: 5px 35px 0 35px;
			display: flex;
			flex-direction: column;
		}

		label {
			margin-top: 15px;
			color: #707388;
			font-weight: Regular;
			font-size: 16px;
		}

		select {
			height: 44px;
			font-size: 16px;
			color: #707388;
			font-weight: Regular;
			border-radius: 3px;
			border-color: #E8E9EB;
		}

		textarea {
			font-size: 16px;
			color: #707388;
			font-weight: Regular;
			border-radius: 3px;
			border-color: #E8E9EB;
		}

		.footer {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 30px 35px 37px 35px;
		}

		button {
			width: 48%;
		}

		.cancel {
			color: var(--color-green-3);
			background: none;
		}

		.cancel:hover {
			color: #63C6A3;
			background: none;
		}
	`
];
__decorate([
    property({ type: Boolean, reflect: true })
], TransferDialog.prototype, "isHidden", void 0);
__decorate([
    property({ type: Number })
], TransferDialog.prototype, "eventId", void 0);
__decorate([
    property({ type: Number })
], TransferDialog.prototype, "toEventId", void 0);
__decorate([
    property({ type: Number })
], TransferDialog.prototype, "toPriceId", void 0);
__decorate([
    property({ type: Array })
], TransferDialog.prototype, "events", void 0);
__decorate([
    property({ type: Array })
], TransferDialog.prototype, "prices", void 0);
__decorate([
    query('#note', true)
], TransferDialog.prototype, "note", void 0);
TransferDialog = __decorate([
    customElement(TransferDialogTag)
], TransferDialog);
export { TransferDialog };
