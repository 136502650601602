import { initColoredIcons } from './canvas/canvas-ctx';
import { getPriceLevelPriceRangeText, getPriceLevelPrices } from './prices';

//[{ id, name, color, priceRangeText, prices: [{ id, priceLevelId, price, name }] }]
let _priceLevels = [];

export function initPriceLevels(priceLevels) {
	const colors = [];
	_priceLevels = [];
	priceLevels.forEach(priceLevel => {
		colors.push(priceLevel.color);
		_priceLevels.push({
			id: priceLevel.id,
			name: priceLevel.name,
			color: priceLevel.color,
			priceRangeText: getPriceLevelPriceRangeText(priceLevel.id),
			prices: getPriceLevelPrices(priceLevel.id)
		});
	});

	initColoredIcons(colors);
}

export function getPriceLevel(priceLevelId) {
	return _priceLevels.find(priceLevel => priceLevel.id === priceLevelId) || {};
}

export function getPriceLevels() {
	return _priceLevels;
}

export function getPriceLevelsWithoutPrices() {
	return _priceLevels.filter(priceLevel => priceLevel.prices.length === 0);
}

export function getPriceLevelPriceQuantity(priceLevelId) {
	const priceLevel = getPriceLevel(priceLevelId);
	return priceLevel.prices.length;
}

export function getPriceLevelFirstPrice(priceLevelId) {
	const priceLevel = getPriceLevel(priceLevelId);
	return priceLevel.prices[0] || {};
}
