import { drawShape } from './canvas/canvas-ctx';

let _shapes = [];

export const initShapes = (shapes) => {
	_shapes = shapes || [];
};

export const drawShapes = () => {
	_shapes.forEach(shape => {
		drawShape(shape);
	});
};

