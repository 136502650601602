import { adaSvg, companionSvg, previewSvg } from '../components/icons';
import { checkDrawSelectableHoldSeat, isSeatStateSelected, checkDrawUnavailableSeat } from '../seat-states';
import { polyfill } from './legacy/polyfill';

const SHIFT = 7;
const SHIFT2 = 14;

const SELECTED_SEAT_COLOR = '#333';
const UNAVAILABLE_SEAT_COLOR = '#d4d4d4';
const TABLE_COLOR = '#E3E3E3';

export let seatRadius = 10;

let _ctx;
let _options;

const _coloredIcons = {
	'#fff': {
		ada: new Image(),
		companion: new Image(),
		preview: new Image()
	}
};

_coloredIcons['#fff'].ada.src = getSvgImage(adaSvg, '#fff');
_coloredIcons['#fff'].companion.src = getSvgImage(companionSvg, '#fff');
_coloredIcons['#fff'].preview.src = getSvgImage(previewSvg, '#fff');

export const initCanvasCtx = (canvas, mapWidth, mapHeight, radius) => {
	seatRadius = radius;
	canvas.width = mapWidth;
	canvas.height = mapHeight;
	_ctx = canvas.getContext('2d');
	_ctx.font = '18px Poppins';
	_ctx.textBaseline = 'middle';
	_ctx.clearRect(0, 0, mapWidth, mapHeight);

	// legacy polyfill to support older browsers (iOS 15 and older)
	polyfill(_ctx);
};

export const initColoredIcons = (colors) => {
	for (const color of colors) {
		if (_coloredIcons[color]) {
			continue;
		}
		_coloredIcons[color] = {
			ada: new Image(),
			companion: new Image(),
			preview: new Image()
		};
		_coloredIcons[color].ada.src = getSvgImage(adaSvg, color);
		_coloredIcons[color].companion.src = getSvgImage(companionSvg, color);
		_coloredIcons[color].preview.src = getSvgImage(previewSvg, color);
	}
};

export const initCanvasCtxOptions = (options) => {
	_options = options || {};
};

function getSvgImage(svg, color) {
	const newSvg = svg.strings[0].replace(/fill="\w+"/g, 'fill="' + color + '"');
	return 'data:image/svg+xml;base64,' + window.btoa(newSvg);
}

const radians = (degrees) => degrees * Math.PI / 180;

// ******************************************** SEATS ********************************************
export function drawSeat(seat) {
	clearSeat(seat);
	drawDirtySeat(seat);
}

export function drawDirtySeat(seat) {
	_ctx.beginPath();

	if (isSeatStateSelected(seat.id)) {
		return drawSelectedSeat(seat);
	}

	if (checkDrawSelectableHoldSeat(seat.id)) {
		return drawAvailableHoldSeat(seat);
	}

	if (checkDrawUnavailableSeat(seat.id)) {
		return drawUnavailableSeat(seat);
	}

	drawAvailableSeat(seat);
}

export function drawSelectedSeat(seat) {
	if (seat.isWheelchair) {
		_ctx.roundRect(seat.x - seatRadius, seat.y - seatRadius, seatRadius * 2, seatRadius * 2, 5);
	} else {
		_ctx.arc(seat.x, seat.y, seatRadius, 0, 2 * Math.PI, false);
	}
	_ctx.fillStyle = SELECTED_SEAT_COLOR;
	_ctx.fill();

	if (seat.isAccessible || seat.isWheelchair) {
		_ctx.drawImage(_coloredIcons['#fff'].ada, seat.x - SHIFT, seat.y - SHIFT, SHIFT2, SHIFT2);
	} else if (seat.previewPath && !_options.isPreviewIconsHidden) {
		_ctx.drawImage(_coloredIcons['#fff'].preview, seat.x - SHIFT, seat.y - SHIFT, SHIFT2, SHIFT2);
	} else if (seat.isCompanion) {
		_ctx.drawImage(_coloredIcons['#fff'].companion, seat.x - SHIFT, seat.y - SHIFT, SHIFT2, SHIFT2);
	}
}

export function drawUnavailableSeat(seat) {
	_ctx.arc(seat.x, seat.y, seatRadius, 0, 2 * Math.PI, false);
	_ctx.lineWidth = 1;
	_ctx.strokeStyle = UNAVAILABLE_SEAT_COLOR;
	_ctx.stroke();
}

function drawAvailableHoldSeat(seat) {
	_ctx.lineWidth = 3;
	if (seat.isWheelchair) {
		_ctx.roundRect(seat.x - seatRadius, seat.y - seatRadius, seatRadius * 2, seatRadius * 2, 5);
	} else {
		_ctx.arc(seat.x, seat.y, seatRadius, 0, 2 * Math.PI, false);
	}
	_ctx.strokeStyle = seat.color;
	_ctx.stroke();

	if (seat.isAccessible || seat.isWheelchair) {
		_ctx.drawImage(_coloredIcons[seat.color].ada, seat.x - SHIFT, seat.y - SHIFT, SHIFT2, SHIFT2);
	} else if (seat.previewPath && !_options.isPreviewIconsHidden) {
		_ctx.drawImage(_coloredIcons[seat.color].preview, seat.x - SHIFT, seat.y - SHIFT, SHIFT2, SHIFT2);
	} else if (seat.isCompanion) {
		_ctx.drawImage(_coloredIcons[seat.color].companion, seat.x - SHIFT, seat.y - SHIFT, SHIFT2, SHIFT2);
	}
}

function drawAvailableSeat(seat) {
	if (seat.isWheelchair) {
		_ctx.roundRect(seat.x - seatRadius, seat.y - seatRadius, seatRadius * 2, seatRadius * 2, 5);
	} else {
		_ctx.arc(seat.x, seat.y, seatRadius, 0, 2 * Math.PI, false);
	}
	_ctx.fillStyle = seat.color;
	_ctx.fill();

	if (seat.isAccessible || seat.isWheelchair) {
		_ctx.drawImage(_coloredIcons['#fff'].ada, seat.x - SHIFT, seat.y - SHIFT, SHIFT2, SHIFT2);
	} else if (seat.previewPath && !_options.isPreviewIconsHidden) {
		_ctx.drawImage(_coloredIcons['#fff'].preview, seat.x - SHIFT, seat.y - SHIFT, SHIFT2, SHIFT2);
	} else if (seat.isCompanion) {
		_ctx.drawImage(_coloredIcons['#fff'].companion, seat.x - SHIFT, seat.y - SHIFT, SHIFT2, SHIFT2);
	}
}

export function drawAnimatedSeat(seat) {
	const period = 500;  //miliseconds
	const linearMod = (Date.now() - seat.animationStartTime) % period / period; // this goes from 0 to 1
	const mod = Math.sin(linearMod * Math.PI);
	const r = seatRadius * mod;

	clearSeat(seat);
	_ctx.beginPath();
	if (seat.isWheelchair) {
		_ctx.roundRect(seat.x - r, seat.y - r, r * 2, r * 2, 5);
	} else {
		_ctx.arc(seat.x, seat.y, r, 0, 2 * Math.PI, false);
	}
	_ctx.fillStyle = seat.isUnselect ? seat.color : SELECTED_SEAT_COLOR;
	_ctx.fill();
	_ctx.lineWidth = 1;
	_ctx.strokeStyle = UNAVAILABLE_SEAT_COLOR;
	_ctx.stroke();
}

function clearSeat(seat) {
	_ctx.save();
	_ctx.beginPath();
	_ctx.globalCompositeOperation = 'destination-out';
	_ctx.fillStyle = '#ffffff';
	if (seat.isWheelchair) {
		_ctx.roundRect(seat.x - seatRadius, seat.y - seatRadius, seatRadius * 2, seatRadius * 2, 5);
	} else {
		_ctx.arc(seat.x, seat.y, seatRadius, 0, 2 * Math.PI, false);
	}
	_ctx.fill();
	_ctx.restore();
}

// ******************************************** OBJECTS ********************************************
export function drawTable(table) {
	if (table.angle !== 0 && table.width) {
		_ctx.save();
		_ctx.translate(table.x, table.y);
		_ctx.rotate(radians(table.angle));
		table.x = 0;
		table.y = 0;
	}

	_ctx.beginPath();
	if (table.radius) {
		_ctx.arc(table.x, table.y, table.radius, 0, 2 * Math.PI, false);
	} else if (table.width) {
		const height = table.height || table.width;
		_ctx.rect(table.x - table.width / 2, table.y - height / 2, table.width, height);
	}

	_ctx.lineWidth = 2;
	_ctx.strokeStyle = TABLE_COLOR;
	_ctx.stroke();

	if (table.angle !== 0 && table.width) {
		_ctx.restore();
	}
}

export function drawImage(image) {
	if (image.angle !== 0 && image.width) {
		_ctx.save();
		_ctx.translate(image.x, image.y);
		_ctx.rotate(radians(image.angle));
		image.x = 0;
		image.y = 0;
	}

	_ctx.beginPath();
	_ctx.drawImage(image.img, image.x - image.width / 2, image.y - image.height / 2, image.width, image.height);

	if (image.angle !== 0 && image.width) {
		_ctx.restore();
	}
}

export function drawLabel(label) {
	_ctx.textAlign = label.align;
	_ctx.fillStyle = label.color;
	_ctx.strokeStyle = label.color;

	if (label.angle) {
		_ctx.save();
		_ctx.translate(label.x, label.y);
		_ctx.rotate(radians(label.angle));
		_ctx.fillText(label.label, 0, 0);
		_ctx.restore();
	} else {
		_ctx.fillText(label.label, label.x, label.y);
	}
}

// ******************************************** SHAPES ********************************************
export const drawShape = (shape) => {
	// console.log('[drawShape]', JSON.stringify(shape));
	// console.log('[drawShape]', shape);

	_ctx.beginPath();

	switch (shape.shape) {
		case 'line':
			_ctx.moveTo(shape.x1, shape.y1);
			_ctx.lineTo(shape.x2, shape.y2);
			break;

		case 'circle':
			_ctx.arc(shape.x, shape.y, shape.radius, 0, 2 * Math.PI);
			break;

		case 'square':
			_ctx.rect(shape.x, shape.y, shape.width, shape.width);
			break;

		case 'rectangle':
			_ctx.rect(shape.x, shape.y, shape.width, shape.height);
			break;

		case 'curve':
			drawCurve(shape);
			break;

		default:
			console.warn('[drawShape] Unsupported shape type: ', shape);
	}

	_ctx.closePath();

	if (shape.style === 'outlined' || shape.shape === 'line') {
		_ctx.lineWidth = shape.lineWidth;
		_ctx.strokeStyle = shape.color;
		_ctx.stroke();
	} else {
		_ctx.fillStyle = shape.color;
		_ctx.fill();
	}
};

const drawCurve = (curve) => {
	_ctx.moveTo(curve.pathCenterCurvex, curve.pathCenterCurvey);
	_ctx.quadraticCurveTo(curve.path1dx, curve.path1dy, curve.path2dx, curve.path2dy);
	_ctx.lineWidth = curve.lineWidth;
	_ctx.strokeStyle = curve.color;
	_ctx.stroke();
};
