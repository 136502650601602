import Hammer from '@egjs/hammerjs';
import { getMapSeatInRange } from '../../map-seats';
const MOUSE_STOP_DELAY = 25;
export class MapViewGesturesController {
    constructor(host) {
        this.dragStartX = 0;
        this.dragStartY = 0;
        this.dragStartAreaSelectFrameX = 0;
        this.dragStartAreaSelectFrameY = 0;
        this.dragStartShiftState = false;
        this.scaleStartScale = 0;
        this.isScaling = false;
        this.wheelZoomSpeed = 0.002;
        this.mapShiftX = 0;
        this.mapShiftY = 0;
        this.canvas2mini = 1;
        this.mini2canvas = 1;
        // *********************************************** MOUSE HANDLERS ***********************************************
        this.handleMouseMove = (event) => {
            clearTimeout(this.mouseStopTimerId);
            if (this.dragStartShiftState) {
                return;
            }
            this.mouseStopTimerId = setTimeout(() => {
                this.handleMouseStop(event);
            }, MOUSE_STOP_DELAY);
        };
        this.handleClick = (event) => {
            const mapCanvasRect = this.host.canvas.getBoundingClientRect();
            const canvasMouseLeft = event.clientX - mapCanvasRect.left;
            const canvasMouseTop = event.clientY - mapCanvasRect.top;
            const mapSeat = getMapSeatInRange(canvasMouseLeft / this.host.scale, canvasMouseTop / this.host.scale);
            if (!mapSeat) {
                return;
            }
            this.host.dispatchEvent(new CustomEvent('onSeatClick', {
                bubbles: true,
                composed: true,
                detail: {
                    seatId: mapSeat.id,
                    isShiftKey: event.shiftKey
                }
            }));
            // this.host.seats.handleSeatClick(mapSeat.id, event.shiftKey);
        };
        this.handleDoubleTap = () => {
            // let newScale = minScale;
            // setCurrentCoordinates(currentX - event.center.x, currentY - event.center.y);
            // if (currentScale < (maxScale + minScale) / 2) {
            // 	newScale = maxScale;
            // 	setCurrentCoordinates(0, 0);
            // }
            // setCurrentScale(newScale);
            // updateLayout(true);
        };
        // *********************************************** SCALE HANDLERS ***********************************************
        this.handleWheel = (event) => {
            event.preventDefault();
            this.host.scale = Math.max(Math.min(this.host.maxScale, this.host.scale - event.deltaY * this.wheelZoomSpeed), this.host.minScale);
            this.host.style.setProperty('--map-scale', this.host.scale.toString());
            this.minimapPanel.scale = this.host.scale;
        };
        this.handleMapScaleStart = () => {
            this.isScaling = true;
            this.scaleStartScale = this.host.scale;
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.handleMapScaleChange = (event) => {
            const scale = Math.max(Math.min(this.host.maxScale, this.scaleStartScale * event.scale), this.host.minScale);
            this.host.scale = scale;
            this.host.style.setProperty('--map-scale', scale.toString());
            this.minimapPanel.scale = scale;
        };
        this.handleMapScaleEnd = () => {
            this.isScaling = false;
        };
        // *********************************************** DRAG HANDLERS ***********************************************
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.handleDragStart = (event) => {
            this.dragStartShiftState = event.srcEvent.shiftKey;
            this.dragStartX = this.mapShiftX;
            this.dragStartY = this.mapShiftY;
            if (this.dragStartShiftState && this.host.enableAreaSelect) {
                const mapViewRect = this.host.getBoundingClientRect();
                this.dragStartAreaSelectFrameX = event.srcEvent.clientX - mapViewRect.left;
                this.dragStartAreaSelectFrameY = event.srcEvent.clientY - mapViewRect.top;
                this.setSelectionFrameSize(0, 0);
                this.areaSelectFrame.style.display = 'block';
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.handleDrag = (event) => {
            if (this.host.enableAreaSelect && this.dragStartShiftState) {
                this.setSelectionFrameSize(event.deltaX, event.deltaY);
            }
            else if (!this.isScaling) {
                this.mapPositionChange(this.dragStartX + event.deltaX / this.host.scale, this.dragStartY + event.deltaY / this.host.scale);
            }
            if (!this.isScaling) {
                const mapShiftX = this.dragStartX + event.deltaX / this.host.scale;
                const mapShiftY = this.dragStartY + event.deltaY / this.host.scale;
                this.mapPositionChange(mapShiftX, mapShiftY);
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.handleDragEnd = (event) => {
            if (this.host.enableAreaSelect && this.dragStartShiftState) {
                const mapCanvasRect = this.host.canvas.getBoundingClientRect();
                const canvasMouseLeft = event.srcEvent.clientX - mapCanvasRect.left;
                const canvasMouseTop = event.srcEvent.clientY - mapCanvasRect.top;
                const left = Math.min(canvasMouseLeft, canvasMouseLeft - event.deltaX);
                const top = Math.min(canvasMouseTop, canvasMouseTop - event.deltaY);
                const width = Math.abs(event.deltaX);
                const height = Math.abs(event.deltaY);
                this.areaSelectFrame.style.display = 'none';
                this.host.dispatchEvent(new CustomEvent('onAreaSelect', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        x0: left * this.host.scale,
                        y0: top * this.host.scale,
                        x1: (left + width) * this.host.scale,
                        y1: (top + height) * this.host.scale
                    }
                }));
            }
            this.dragStartShiftState = false;
        };
        // *********************************************** POSITION HADLERS ***********************************************
        this.mapPositionChange = (mapShiftX, mapShiftY) => {
            this.mapShiftX = mapShiftX;
            this.mapShiftY = mapShiftY;
            this.host.minimapPanel.minimapShiftX = -mapShiftX * this.canvas2mini;
            this.host.minimapPanel.minimapShiftY = -mapShiftY * this.canvas2mini;
            this.host.style.setProperty('--map-shift-x', `${mapShiftX}px`);
            this.host.style.setProperty('--map-shift-y', `${mapShiftY}px`);
        };
        this.host = host;
        host.addController(this);
        setTimeout(() => {
            this.initEventListeners();
        }, 100);
    }
    get mapView() {
        return this.host.mapView;
    }
    get areaSelectFrame() {
        return this.host.areaSelectFrame;
    }
    get minimapPanel() {
        return this.host.minimapPanel;
    }
    hostConnected() {
    }
    hostUpdated() {
    }
    hostDisconnected() {
    }
    initEventListeners() {
        this.mapView.addEventListener('mousemove', this.handleMouseMove);
        this.mapView.addEventListener('click', this.handleClick);
        this.mapView.addEventListener('wheel', this.handleWheel);
        this.minimapPanel.addEventListener('onScaleChange', (event) => {
            this.handleMinimapScaleChange(event);
        });
        this.minimapPanel.addEventListener('onPositionChange', (event) => {
            this.handleMinimapPositionChange(event);
        });
        this.manager = new Hammer.Manager(this.mapView);
        const singleTap = new Hammer.Tap();
        const doubleTap = new Hammer.Tap({ event: 'doubletap', taps: 2, posThreshold: 20 });
        this.manager.add([doubleTap, singleTap]);
        doubleTap.recognizeWith(singleTap);
        singleTap.requireFailure(doubleTap);
        this.manager.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
        this.manager.add(new Hammer.Pinch({ threshold: 0 })).recognizeWith([this.manager.get('pan')]);
        this.manager.on('doubletap', this.handleDoubleTap);
        this.manager.on('pinchstart', this.handleMapScaleStart);
        this.manager.on('pinchmove', this.handleMapScaleChange);
        this.manager.on('pinchend', this.handleMapScaleEnd);
        this.manager.on('panstart', this.handleDragStart);
        this.manager.on('panend', this.handleDragEnd);
        this.manager.on('panmove', this.handleDrag);
    }
    handleMouseStop(event) {
        const mapCanvasRect = this.host.canvas.getBoundingClientRect();
        const canvasMouseLeft = event.clientX - mapCanvasRect.left;
        const canvasMouseTop = event.clientY - mapCanvasRect.top;
        const mapSeat = getMapSeatInRange(canvasMouseLeft / this.host.scale, canvasMouseTop / this.host.scale) || { id: 0, x: 0, y: 0 };
        this.host.dispatchEvent(new CustomEvent('onSeatHover', {
            bubbles: true,
            composed: true,
            detail: {
                seatId: mapSeat.id,
                hintLeft: Math.round(mapSeat.x * this.host.scale + mapCanvasRect.left),
                hintTop: Math.round((mapSeat.y - 2) * this.host.scale + mapCanvasRect.top)
            }
        }));
        // this.host.seats.handleSeatHover(mapSeat.id, Math.round(mapSeat.x * this.host.scale + mapCanvasRect.left), Math.round((mapSeat.y - 2) * this.host.scale + mapCanvasRect.top));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMinimapScaleChange(event) {
        this.host.style.setProperty('--map-scale', event.detail.scale);
        this.host.scale = event.detail.scale;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMinimapPositionChange(event) {
        this.mapShiftX = -event.detail.minimapShiftX * this.mini2canvas;
        this.mapShiftY = -event.detail.minimapShiftY * this.mini2canvas;
        this.host.style.setProperty('--map-shift-x', `${this.mapShiftX}px`);
        this.host.style.setProperty('--map-shift-y', `${this.mapShiftY}px`);
    }
    // *********************************************** OTHER ***********************************************
    setSelectionFrameSize(deltaX, deltaY) {
        this.host.style.setProperty('--area-select-frame-left', Math.min(this.dragStartAreaSelectFrameX, this.dragStartAreaSelectFrameX + deltaX) + 'px');
        this.host.style.setProperty('--area-select-frame-top', Math.min(this.dragStartAreaSelectFrameY, this.dragStartAreaSelectFrameY + deltaY) + 'px');
        this.host.style.setProperty('--area-select-frame-width', Math.abs(deltaX) + 'px');
        this.host.style.setProperty('--area-select-frame-height', Math.abs(deltaY) + 'px');
    }
}
