export const getLocalStorageItem = (key) => {
	try {
		return localStorage.getItem(key) || null;
	} catch (error) {
		console.log('Browser does not support localStorage', error);
		return null;
	}
};

export const setLocalStorageItem = (key, value) => {
	try {
		localStorage.setItem(key, value);
	} catch (error) {
		console.log('Browser does not support localStorage', error);
	}
};
