var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { adaSvg } from '../icons';
import { buttonStyles, headerStyles } from '../styles';
import { setLocalStorageItem } from '../../local-storage';
export const INSTRUCTION_DIALOG_KEY = 'ase3.settings.isInstructionHidden';
export const InstructionDialogTag = 'instruction-dialog';
let InstructionDialog = class InstructionDialog extends LitElement {
    constructor() {
        super(...arguments);
        this.isHidden = true;
    }
    render() {
        return html `
			<div class="container">
				<div class="header">Interactive Seating Map</div>
				<div class="steps">
					<div class="item">
						<div class="number">1</div>
						<div class="image icon1"></div>
						<div class="title">Select the section</div>
					</div>
					<div class="item">
						<div class="number">2</div>
						<div class="image icon2"></div>
						<div class="title">Choose your Seat</div>
					</div>
					<div class="item">
						<div class="number active">3</div>
						<div class="image icon3"></div>
						<div class="title">Click <strong>Checkout</strong></div>
					</div>
				</div>
				<div class="tips">
					<div class="title">Tips</div>
					<ul>
						<li>Click and drag to move the map</li>
						<li>Zoom using the Zoom In/Out buttons</li>
						<li>Click anywhere on the preview map at the lower left of the screen to jump to any section on the map</li>
					</ul>
				</div>
				<div class="seats">
					<div class="item">
						<div class="seat default"></div>
						<div class="title">Default</div>
					</div>
					<div class="item">
						<div class="seat selected"></div>
						<div class="title">Selected</div>
					</div>
					<div class="item">
						<div class="seat sold"></div>
						<div class="title">Sold</div>
					</div>
					<div class="item">
						<div class="seat ada">
							${adaSvg}
						</div>
						<div class="title">ADA Accessible</div>
					</div>
				</div>
				<div class="footer"><button type="button" @click="${this.handleCloseClick}" name="Close">Close</button></div>
			</div>
		`;
    }
    handleCloseClick() {
        this.isHidden = true;
        setLocalStorageItem(INSTRUCTION_DIALOG_KEY, '1');
    }
};
InstructionDialog.styles = [
    buttonStyles,
    headerStyles,
    css `
		:host {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.4);
			z-index: 100;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		:host([ishidden]) {
			display: none;
		}

		.container{
			background: var(--color-main-background);
			box-shadow: 0px 1px 9.4px 0.6px rgba(154, 154, 154, 0.24);
			border-radius: 5px;
			width: 100%;
			max-width: 561px;
			min-height: 462px;
			/* padding: 37px 35px; */
		}

		.footer {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
		}

		.steps{
			display: flex;
			justify-content: space-between;
			padding: 50px 14px 0;
		}

		.steps .item {
			width: 32%;
			min-height: 126px;
			border-radius: 5px;
			border: solid 1px var(--color-grey-30);
			position: relative;
			text-align: center;
		}

		.steps .number {
			background-color: var(--color-grey-23);
			width: 40px;
			height: 40px;
			position: absolute;
			border-radius: 50%;
			color: var(--color-white);
			font-size: 18px;
			top: -27px;
			left: 0;
			right: 0;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.steps .active {
			background-color: var(--color-accent);
		}

		.steps .image {
			width: 50px;
			height: 50px;
			margin: 24px auto 0px;

		}

		.steps .icon1 {
			background-image: url(/images/ase3/dialogs/instruction-dialog/step1.png);
		}

		.steps .icon2 {
			background-image: url(/images/ase3/dialogs/instruction-dialog/step2.png);
		}

		.steps .icon3 {
			background-image: url(/images/ase3/dialogs/instruction-dialog/step3.png);
		}

		.steps .title {
			font-family: AvenirNextCyr-Demi, sans-serif;
			font-size: 15px;
			font-weight: 500;
			letter-spacing: 1.5px;
			color: var(--color-grey-6);
			line-height: 1;
			margin-top: 18px;
		}

		/* .header {
			height: 36px;
			text-transform: uppercase;
			text-align: center;
			font-family: AvenirNextCyr-Demi, sans-serif;
			font-size: 12px;
			letter-spacing: 1.2px;
			background-color: var(--color-accent);
			color: var(--color-white);
			padding-top: 10px;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		} */

		.seats {
			margin-top: 32px;
			padding-bottom: 20px;
			display: flex;
			justify-content: center;
		}

		.seat {
			width: 30px;
			height: 30px;
			padding: 0;
			font-family: AvenirLTStd, sans-serif;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 1.4px;
			border-radius: 50%;
			border: solid 0 var(--color-white);
			color: var(--color-white);
			text-align: center;
		}

		.seats .item {
			margin: 0 8px;
			padding: 3px 5px 0px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.seats .title {
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 1.2px;
			color: var(--color-grey-17);
		}

		.default {
			background-color: var(--color-accent);
		}

		.selected {
			background-color: #252122;
		}

		.sold {
			width: 26px;
			height: 26px;
			border: solid 2px #d4d4d4;
			background-color: var(--color-white);
		}

		.ada {
			background-color: var(--color-accent);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.ada svg {
			width: 14px;
			height: 14px;
		}

		.tips{
			margin-top: 21px;
			padding: 0 16px;
		}

		.tips .title {
			font-family: AvenirNextCyr-Demi, sans-serif;
			font-size: 16px;
			letter-spacing: 1.6px;
			color: var(--color-grey-6);
		}

		.tips ul {
			margin: 5px 0 0 0;
			padding: 0;
			list-style-type: none;
		}

		.tips li {
			font-family: AvenirNextCyr, sans-serif;
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 1.2px;
			line-height: 1.5;
			color: var(--color-grey-6);
			margin-top: 10px;
		}

		.tips li::before {
			content: '';
			display: inline-block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: var(--color-grey-23);
			margin-right: 7px;
		}

		button {
			width: 162px;
		}
	`
];
__decorate([
    property({ type: Boolean, reflect: true })
], InstructionDialog.prototype, "isHidden", void 0);
InstructionDialog = __decorate([
    customElement(InstructionDialogTag)
], InstructionDialog);
export { InstructionDialog };
