var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { fetchCallback, getData, setSessionId, setUrl } from '../../bus';
import { usMoney, eventDate } from '../../formats';
import { initUserType } from '../../mode';
import { buttonStyles } from '../styles';
export const OrderInfoTag = 'order-info';
let OrderInfo = class OrderInfo extends LitElement {
    constructor() {
        super(...arguments);
        this.isHidden = true;
        this.isMobile = false;
        this.eventId = 0;
        this.seatId = 0;
        this.clId = 0;
        this.orderInfo = {};
    }
    render() {
        const orderInfo = this.orderInfo;
        const price = orderInfo.price ? usMoney(orderInfo.price) : '-';
        const subtotal = orderInfo.subtotal ? usMoney(orderInfo.subtotal) : '-';
        const total = orderInfo.total ? usMoney(orderInfo.total) : '-';
        const isCustomerDetailsHidden = !(this.orderInfo.guestName || this.orderInfo.purchaserName || this.orderInfo.phone || this.orderInfo.email || this.orderInfo.address);
        const seatInfos = orderInfo.seatInfos || [];
        seatInfos.sort();
        return html `
			<div class="container">
				<div class="header">
					<div class="header-title">${orderInfo.title}</div>
				</div>
				<div class="body">
					<div class="body-title">Order details</div>
					<div class="line">
						<div class="cell">
							<div class="cell-title">EVENT</div>
							<div class="cell-data">${orderInfo.eventName}</div>
						</div>
						<div class="cell">
							<div class="cell-title">EVENT DATE</div>
							<div class="cell-data">${eventDate(orderInfo.eventDate)}</div>
						</div>
					</div>
					<div class="line">
						<div class="cell">
							<div class="cell-title">PRICE</div>
							<div class="cell-data">${price}</div>
						</div>
						<div class="cell">
							<div class="cell-title">QUANTITY</div>
							<div class="cell-data">${orderInfo.quantity}</div>
						</div>
					</div>
					<div class="line">
						<div class="cell">
							<div class="cell-title">TICKET TYPE</div>
							<div class="cell-data">${orderInfo.ticketTypeName}</div>
						</div>
						<div class="cell">
							<div class="cell-title">DELIVERY METHOD</div>
							<div class="cell-data">${orderInfo.deliveryMethod || '-'}</div>
						</div>
					</div>
					<div class="line">
						<div class="cell-title">SEATS</div>
					</div>
					${seatInfos.map((seat) => html `
						<div class="seat-line">${seat}</div>
					`)}

					<div>
					<div class="body-title" ?hidden=${isCustomerDetailsHidden}>Customer details</div>
					<div class="line" ?hidden=${!(orderInfo.guestName || orderInfo.purchaserName)}>
						<div class="cell">
							<div class="cell-title">GUEST NAME</div>
							<div class="cell-data">${orderInfo.guestName || '-'}</div>
						</div>
						<div class="cell">
							<div class="cell-title">PURCHASER NAME</div>
							<div class="cell-data">${orderInfo.purchaserName || '-'}</div>
						</div>
					</div>

					<div class="line" ?hidden=${!(orderInfo.phone || orderInfo.email)}>
						<div class="cell">
							<div class="cell-title">PHONE</div>
							<div class="cell-data">${orderInfo.phone || '-'}</div>
						</div>
						<div class="cell">
							<div class="cell-title">EMAIL</div>
							<div class="cell-data">${orderInfo.email || '-'}</div>
						</div>
					</div>

					<div class="line" ?hidden=${!orderInfo.address}>
						<div class="cell">
							<div class="cell-title">ADDRESS</div>
							<div class="cell-data">${orderInfo.address || '-'}</div>
						</div>
					</div>
					</div>


					<div class="body-title">Payment details</div>
					<div class="line">
						<div class="cell">
							<div class="cell-title">TRANSACTION DATE</div>
							<div class="cell-data">${orderInfo.transactionDate || '-'}</div>
						</div>
						<div class="cell">
							<div class="cell-title">TRANSACTION #</div>
							<div class="cell-data">${orderInfo.transactionNumber || '-'}</div>
						</div>
					</div>

					<div class="line">
						<div class="cell">
							<div class="cell-title">SUBTOTAL</div>
							<div class="cell-data">${subtotal}</div>
						</div>
						<div class="cell">
							<div class="cell-title">TRANSACTION TOTAL</div>
							<div class="cell-data">${total}</div>
						</div>
					</div>

					<div class="line">
						<div class="cell">
							<div class="cell-title">PAYMENT METHOD</div>
							<div class="cell-data">${orderInfo.paymentMethod || '-'}</div>
						</div>
						<div class="cell">
							<div class="cell-title">CC</div>
							<div class="cell-data">${orderInfo.cardNumber || '-'}</div>
						</div>
					</div>
				</div>
				<div class="footer">
					<button type="button" class="close-button" @click=${this.handleCloseClick} aria-label="Close">
						Close
					</button>
				</div>
			</div>
		`;
    }
    willUpdate(changedProperties) {
        if (changedProperties.has('seatId') && this.seatId > 0) {
            this.isHidden = false;
            return this.requestOrderInfoBySeat();
        }
        if (changedProperties.has('clId') && this.clId > 0) {
            this.isHidden = false;
            return this.requestOrderInfoByCl();
        }
    }
    handleCloseClick() {
        this.isHidden = true;
        this.clId = 0;
        this.seatId = 0;
        this.dispatchEventUp('oncloseorderinfo');
    }
    requestOrderInfoBySeat() {
        const params = { eventId: this.eventId, seatId: this.seatId };
        return this.requestOrderInfo(params);
    }
    requestOrderInfoByCl() {
        const params = { eventId: this.eventId, clId: this.clId };
        return this.requestOrderInfo(params);
    }
    async requestOrderInfo(params) {
        const responseJson = await getData('/ase3/actions/orders/getOrderInfoBySeatId.php', params);
        if (!fetchCallback(responseJson)) {
            return { title: 'Read order info error #1683117176' };
        }
        this.orderInfo = responseJson;
    }
    // ***************************************** OTHER *****************************************
    dispatchEventUp(eventName, detail = {}) {
        this.dispatchEvent(new CustomEvent(eventName, {
            detail,
            bubbles: true,
            composed: true
        }));
    }
    setSessionId(sessionId) {
        setSessionId(sessionId);
    }
    setUrl(url) {
        setUrl(url);
    }
    setUserType(userType) {
        initUserType(userType);
    }
};
OrderInfo.styles = [
    buttonStyles,
    css `
		:host {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.4);
			z-index: 100;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		:host([isHidden]) {
			display: none;
		}

		.container{
			background: var(--color-main-background);
			box-shadow: 0px 1px 9.4px 0.6px rgba(154, 154, 154, 0.24);
			border-radius: 5px;
			width: 670px;
			max-height: 90%;
			display:flex;
			flex-direction:column;
		}

		:host([ismobile]) .container{
			width: 100%;
			max-height: 100%;
		}

		.header {
			border-bottom: 1px solid #E7E8ED;
			padding: 16px;
		}

		.header-title {
			color: var(--color-title);
			text-align:center;
			font-size:30px;
			font-weight: lighter;
		}

		.body {
			padding: 12px 35px;
			overflow-y: auto;
			flex: 1;
		}

		.body-title {
			padding-top: 20px;
			color: var(--color-title);
			font-size: 24px;
			font-weight: lighter;
		}

		.line {
			display: flex;
			flex-direction: row;
		}

		:host([ismobile]) .line{
			flex-direction: column;
		}

		.cell {
			display: flex;
			flex-direction: column;
		}

		.cell-title {
			padding-top: 16px;
			width: 300px;
			color: #6F7287;
 			font-weight: semi-bold;
  			font-size: 13px;
		}

		.cell-data {
			width: 300px;
			color: #000833;
  			font-size: 16px;
		}

		.footer {
			border-top: 1px solid #E7E8ED;
			padding: 15px 0px 15px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		button {
			width: 205px;
		}

		[hidden] {
			display:none;
		}
	`
];
__decorate([
    property({ type: Boolean, reflect: true })
], OrderInfo.prototype, "isHidden", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], OrderInfo.prototype, "isMobile", void 0);
__decorate([
    property({ type: Number })
], OrderInfo.prototype, "eventId", void 0);
__decorate([
    property({ type: Number, reflect: true })
], OrderInfo.prototype, "seatId", void 0);
__decorate([
    property({ type: Number, reflect: true })
], OrderInfo.prototype, "clId", void 0);
__decorate([
    property({ type: Object })
], OrderInfo.prototype, "orderInfo", void 0);
OrderInfo = __decorate([
    customElement(OrderInfoTag)
], OrderInfo);
export { OrderInfo };
