import { drawLabel } from './canvas/canvas-ctx';

let currentBlocks = [];
let currentLabels = [];

export function initBlocks(blocks, labels) {
	// todo мы отказадись от лейблов в блоках (leftLabels, rightLabels), но пока оставим их, мало ди, вдруг уже создали ивент на год вперед
	currentBlocks = blocks || [];
	currentLabels = labels || [];

	// если передали labels, то в блоках их не будет (leftLabels, rightLabels) - это временное решение. Пускай какое-то время останется, а то мало ли, может кто насоздавал ивентов на год вперед
	if (currentLabels.length) {
		return;
	}

	currentBlocks.forEach(block => {
		// а плохо это потому, что их просто можно и не задать и поэтому условии выше не сработает. Поэтому проверку ниже if (block.leftLabels) оставляй пока
		if (block.leftLabels) {
			block.leftLabels.forEach(label => {
				currentLabels.push(label);
			});
		}

		if (block.rightLabels) {
			block.rightLabels.forEach(label => {
				currentLabels.push(label);
			});
		}
	});
}

export function getBlock(blockId) {
	return currentBlocks.find(block => block.id === blockId) || {};
}

export function drawBlockLabels() {
	currentLabels.forEach(label => {
		drawLabel(label);
	});
}
