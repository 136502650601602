var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { MapViewGesturesController } from './map-panel-gestures-controller';
import { MinimapPanelTag } from './minimap-panel';
import { PricesToChoosePanelTag } from './prices-to-choose-panel';
const MINIMAP_WIDTH = 221;
const MINIMAP_HEIGHT = 227;
export const MapPanelTag = 'map-panel';
let MapPanel = class MapPanel extends LitElement {
    constructor() {
        super(...arguments);
        this.isMobile = false;
        this.scale = 1;
        this.minScale = 0.1;
        this.maxScale = 2;
        this.gestures = new MapViewGesturesController(this);
        this.enableAreaSelect = false;
    }
    render() {
        return html `
			<div class="map-view">
				<canvas></canvas>
				<div class="area-select-frame"></div>
			</div>

			<prices-to-choose-panel ?isMobile=${this.isMobile}> </prices-to-choose-panel>
			<minimap-panel minscale=${this.minScale} maxscale=${this.maxScale}> </minimap-panel>
		`;
    }
    async initMap(width, height, backgroundColor) {
        this.minScale = Math.min(1, this.mapView.offsetWidth / width, this.mapView.offsetHeight / height);
        this.minimapPanel.scale = this.scale = this.minScale;
        this.gestures.mini2canvas = Math.max(width / MINIMAP_WIDTH, height / MINIMAP_HEIGHT);
        this.gestures.canvas2mini = 1 / this.gestures.mini2canvas;
        this.style.setProperty('--map-background-color', backgroundColor);
        this.style.setProperty('--map-scale', this.minScale.toString());
        this.style.setProperty('--map-width', `${width}px`);
        this.style.setProperty('--map-height', `${height}px`);
        this.style.setProperty('--map-view-width', `${this.mapView.offsetWidth}px`);
        this.style.setProperty('--map-view-height', `${this.mapView.offsetHeight}px`);
        this.style.setProperty('--canvas-2-mini', this.gestures.canvas2mini.toString());
        this.minimapPanel.setBackgroundImage(this.canvas, this.gestures.canvas2mini);
    }
};
MapPanel.styles = css `
		:host {
			--map-background-color: #ffffff;
			--map-width: 0px;
			--map-height: 0px;
			--map-view-width: 0px;
			--map-view-height: 0px;
			--canvas-2-mini: 1;

			--map-shift-x: 0px;
			--map-shift-y: 0px;

			--area-select-frame-width: 0px;
			--area-select-frame-height: 0px;
			--area-select-frame-left: 0px;
			--area-select-frame-top: 0px;

			display: flex;
			transition: opacity 0.3s ease-in-out;
			position: relative;
		}

		minimap-panel {
			position: absolute;
			left: 36px;
			bottom: 36px;
		}

		.map-view {
			background-color: var(--map-background-color);
			overflow: hidden;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		canvas {
			background-color: var(--map-background-color);
			position: absolute;
			width: var(--map-width);
			height: var(--map-height);
			transform: translate(calc(var(--map-shift-x) * var(--map-scale)), calc(var(--map-shift-y) * var(--map-scale))) scale(var(--map-scale));
		}

		prices-to-choose-panel {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 0;
		}

		.area-select-frame {
			width: var(--area-select-frame-width);
			height: var(--area-select-frame-height);
			left: var(--area-select-frame-left);
			top: var(--area-select-frame-top);

			display: none;
			border: 0.3px solid var(--color-accent);
			box-shadow: 0 0 0 0.3px var(--color-accent);
			position: absolute;
			background: #00000017;
			pointer-events: none;
		}

		:host([ismobile]) minimap-panel{
			display: none;
		}
	`;
__decorate([
    property({ type: Boolean })
], MapPanel.prototype, "isMobile", void 0);
__decorate([
    property({ type: Number, state: true })
], MapPanel.prototype, "scale", void 0);
__decorate([
    property({ type: Number, state: true })
], MapPanel.prototype, "minScale", void 0);
__decorate([
    property({ type: Number, state: true })
], MapPanel.prototype, "maxScale", void 0);
__decorate([
    query('canvas', true)
], MapPanel.prototype, "canvas", void 0);
__decorate([
    query('.map-view', true)
], MapPanel.prototype, "mapView", void 0);
__decorate([
    query('.area-select-frame', true)
], MapPanel.prototype, "areaSelectFrame", void 0);
__decorate([
    query(MinimapPanelTag, true)
], MapPanel.prototype, "minimapPanel", void 0);
__decorate([
    query(PricesToChoosePanelTag, true)
], MapPanel.prototype, "pricesToChoosePanel", void 0);
MapPanel = __decorate([
    customElement(MapPanelTag)
], MapPanel);
export { MapPanel };
