var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import './scale-input';
export const MinimapPanelTag = 'minimap-panel';
let MinimapPanel = class MinimapPanel extends LitElement {
    constructor() {
        super(...arguments);
        this.minScale = 0.3;
        this.maxScale = 2;
        this.scale = 1;
        this.minimapShiftX = 0;
        this.minimapShiftY = 0;
    }
    render() {
        return html `
			<div class="minimap-view" @click=${this.handleClick} @mousemove=${this.handleMouseMove}
				@mouseout=${this.handleMouseOut}>
				<canvas></canvas>
				<div class="fog">
					<div class="view"></div>
				</div>
			</div>

			<scale-input minscale=${this.minScale} maxscale=${this.maxScale} scale=${this.scale}>
			</scale-input>
		`;
    }
    updated() {
        this.fog.style.transform = `translate(${this.minimapShiftX}px, ${this.minimapShiftY}px)`;
    }
    handleMouseMove(event) {
        const rect = this.minimapView.getBoundingClientRect();
        const minimapShiftX = event.clientX - rect.left - this.minimapView.offsetWidth / 2;
        const minimapShiftY = event.clientY - rect.top - this.minimapView.offsetHeight / 2;
        this.fog.style.transform = `translate(${minimapShiftX}px, ${minimapShiftY}px)`;
        if (event.buttons < 1) {
            return;
        }
        this.setShifts(minimapShiftX, minimapShiftY);
    }
    handleClick(event) {
        const rect = this.minimapView.getBoundingClientRect();
        this.setShifts(event.clientX - rect.left - this.minimapView.offsetWidth / 2, event.clientY - rect.top - this.minimapView.offsetHeight / 2);
    }
    handleMouseOut() {
        this.requestUpdate();
    }
    setShifts(minimapShiftX, minimapShiftY) {
        this.minimapShiftX = minimapShiftX;
        this.minimapShiftY = minimapShiftY;
        this.dispatchEvent(new CustomEvent('onPositionChange', {
            bubbles: true,
            composed: true,
            detail: { minimapShiftX, minimapShiftY }
        }));
    }
    setBackgroundImage(canvas, canvas2mini) {
        const ctx = this.minimapCanvas.getContext('2d');
        this.minimapCanvas.width = canvas.width * canvas2mini;
        this.minimapCanvas.height = canvas.height * canvas2mini;
        ctx.drawImage(canvas, 0, 0, this.minimapCanvas.width, this.minimapCanvas.height);
    }
};
MinimapPanel.styles = css `
		:host{
			--minimap-fog-width: calc(var(--map-view-width) * var(--canvas-2-mini));
			--minimap-fog-height: calc(var(--map-view-height) * var(--canvas-2-mini));

			--minimap-view-width: 221px;
			--minimap-view-height: 227px;
			--fog-width: 227px;

			width: 307px;
			height: 256px;
			z-index: 2;
			background: #fff;
			box-shadow:0px 4px 8px rgba(0, 0, 0, 0.08);
			border:1px solid #E2E2E2;
			border-radius:3px;
			overflow: hidden;
			box-sizing: border-box;
		}

		.minimap-view {
			background-color: var(--map-background-color);
			width: calc(var(--minimap-view-width) + 2px);
			height: calc(var(--minimap-view-height) + 2px);
			left: 14px;
			top: 14px;
			position: relative;
			border:1px solid #E2E2E2;
			box-sizing: border-box;
			background-size: cover;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.fog{
			box-sizing: content-box;
			position: absolute;
			display: block;
			background: transparent;
			border: var(--fog-width) rgba(0, 0, 0, 0.3) solid;
			width: calc(var(--minimap-fog-width) / var(--map-scale));
			height: calc(var(--minimap-fog-height) / var(--map-scale));
		}

		.view {
			width:100%;
			height:100%;
			border: 2px solid var(--color-accent);
			box-sizing: border-box;
		}

		scale-input {
			position:absolute;
			right:-7px;
			top:15px;
		}
	`;
__decorate([
    property({ type: Number })
], MinimapPanel.prototype, "minScale", void 0);
__decorate([
    property({ type: Number })
], MinimapPanel.prototype, "maxScale", void 0);
__decorate([
    property({ type: Number })
], MinimapPanel.prototype, "scale", void 0);
__decorate([
    property({ type: Number })
], MinimapPanel.prototype, "minimapShiftX", void 0);
__decorate([
    property({ type: Number })
], MinimapPanel.prototype, "minimapShiftY", void 0);
__decorate([
    query('.minimap-view', true)
], MinimapPanel.prototype, "minimapView", void 0);
__decorate([
    query('.fog', true)
], MinimapPanel.prototype, "fog", void 0);
__decorate([
    query('canvas', true)
], MinimapPanel.prototype, "minimapCanvas", void 0);
MinimapPanel = __decorate([
    customElement(MinimapPanelTag)
], MinimapPanel);
export { MinimapPanel };
