import { drawBlockLabels, initBlocks } from '../blocks';
import { createBus, emitLoadEvent, getFileJson } from '../bus';
import { setDistanceSeats } from '../distance-seats';
import { drawImages, initImages } from '../images';
import { initCanvasCtx, initCanvasCtxOptions } from '../canvas/canvas-ctx';
import { drawMapSeats, initMapSeats } from '../map-seats';
import { initMessages } from '../messages';
import { canModeShowBuyInstruction } from '../mode';
import { initPriceLevels } from '../price-levels';
import { initPrices, initXhrPrices } from '../prices';
import { initSeatStates, lockSeatsWithoutPrices } from '../seat-states';
import { initSections } from '../sections';
import { initSuborderSeats } from '../suborder-seats';
import { drawTables, initTables } from '../tables';
import { INSTRUCTION_DIALOG_KEY } from './dialogs/instruction-dialog';
import { getLocalStorageItem } from '../local-storage';
import { initShapes, drawShapes } from '../shapes';

const IS_IOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;
const IOS_MAX_MAP_SIZE = 16777216; // iOS width*height limit
const DEFAULT_SEAT_RADIUS = 10;

export class EventsController {
	host;

	constructor(host) {
		this.host = host;
	}

	// #region SETTERS/GETTERS
	get aseHeader() {
		return this.host.aseHeader;
	}

	get canvas() {
		return this.host.canvas;
	}

	get eventId() {
		return this.host.eventId;
	}

	get instructionDialog() {
		return this.host.instructionDialog;
	}

	get packageId() {
		return this.host.packageId;
	}

	get packageItemIndex() {
		return this.host.packageItemIndex;
	}

	get pricesToChoose() {
		return this.host.pricesToChoose;
	}

	get suborder() {
		return this.host.suborder;
	}

	get url() {
		return this.host.url;
	}

	// #endregion

	init = async () => {
		const eventData = await this.loadEventData(this.eventId);
		const mapInfo = await this.loadMap(eventData.info.mapUrl);

		initMessages(eventData.publicMessages, eventData.privateMessages);
		initCanvasCtx(this.canvas, mapInfo.width, mapInfo.height, DEFAULT_SEAT_RADIUS / mapInfo.interZoom);
		return mapInfo;
	};

	loadEventData = async (eventId) => {
		const params = {
			packageId: this.packageId,
			packageItemIndex: this.packageItemIndex
		};

		createBus(this.host);

		if (this.suborder) {
			params.suborder = this.suborder;
		}

		const eventData = await emitLoadEvent(eventId, params);

		this.aseHeader.eventName = eventData.info.name;
		this.aseHeader.eventStartTime = eventData.info.startTime;

		setDistanceSeats(eventData.info.distance, eventData.distanceSeats);
		initSeatStates(eventData.seatStates, eventData.seatLocks, eventData.selectedSeats, eventData.serverTime);

		if (eventData.prices) {
			await initPrices(Object.values(eventData.prices), this.pricesToChoose);
		} else {
			await initXhrPrices(eventId, this.pricesToChoose);
		}

		initSuborderSeats(eventData.suborderSeatIds);

		return eventData;
	};

	loadMap = async (src) => {
		const map = await getFileJson(src);
		const mapSize = map.width * map.height;

		let interZoom = 1;
		let width = map.width;
		let height = map.height;
		// TODO: supports transparent bg for old maps (now this field is required)
		const backgroundColor = map.backgroundColor || '#ffffff';

		map.size = mapSize;
		this.instructionDialog.isHidden = getLocalStorageItem(INSTRUCTION_DIALOG_KEY) || !canModeShowBuyInstruction() || map.isInstructionHidden;
		initCanvasCtxOptions({ isPreviewIconsHidden: map.isPreviewIconsHidden });

		if (IS_IOS && mapSize > IOS_MAX_MAP_SIZE) {
			interZoom = this.compactMap(map);
			width /= interZoom;
			height /= interZoom;
			console.log(`${map.width} x ${map.height}=${mapSize} / ${interZoom}=${width} x ${height}`);
		}

		// не менять последовальность инициализации ниже, некоторые зависят от других, предыдущих
		await initImages(map.images, this.url);
		initShapes(map.shapes);
		initBlocks(map.blocks, map.labels);
		initPriceLevels(map.priceLevels);
		initSections(map.sections);
		initTables(map.tables);
		initMapSeats(map.seats);
		lockSeatsWithoutPrices();

		return { width, height, interZoom, backgroundColor };
	};

	compactMap = (map) => {
		// https://app.asana.com/0/1206891675847028/1206892188899238/f
		const interZoom = Math.sqrt(map.size / IOS_MAX_MAP_SIZE);

		if (map.seats && map.seats.length) {
			map.seats.forEach(seat => {
				seat.x /= interZoom;
				seat.y /= interZoom;
			});
		}

		if (map.labels && map.labels.length) {
			map.labels.forEach(label => {
				// todo подумай как уменьшиьт размер шрифта, чтобы он в уменьшеном маштабе на iOs был читаем
				label.x /= interZoom;
				label.y /= interZoom;
			});
		}

		if (map.images && map.images.length) {
			map.images.forEach(image => {
				image.x /= interZoom;
				image.y /= interZoom;
				image.height /= interZoom;
				image.width /= interZoom;
			});
		}

		if (map.tables && map.tables.length) {
			map.tables.forEach(table => {
				table.x /= interZoom;
				table.y /= interZoom;
				if (table.radius) {
					table.radius /= interZoom;
					return;
				}

				if (table.width) {
					table.width /= interZoom;
					// если нет height, значит это квадратный стол
					if (table.height) {
						table.height /= interZoom;
					}
				}
			});
		}

		if (map.blocks && map.blocks.length) {
			map.blocks.forEach(block => {
				// у блока координаты нигде не используются пока, но на всякий случай уменьшим
				block.x /= interZoom;
				block.y /= interZoom;
			});
		}

		if (map.texts && map.texts.length) {
			map.texts.forEach(text => {
				// todo та же фигня, как у lables - подумай как уменьшиьт размер шрифта, чтобы он в уменьшеном маштабе на iOs был читаем
				text.x /= interZoom;
				text.y /= interZoom;
			});
		}

		// todo добавить shapes. Коля пока думает что и как, мы сегодня с ним обсудили, но я дождусь как он добавит их в json. Там в зависимости от типа фигуры, надо будет разные параметры уменьшать
		if (map.shapes && map.shapes.length) {
			map.shapes.forEach(shape => {
				shape.x /= interZoom;
				shape.y /= interZoom;
				shape.width /= interZoom;
				shape.height /= interZoom;
			});
		}

		return interZoom;
	};

	drawMap = () => {
		drawImages();
		drawShapes();
		drawTables();
		drawMapSeats();
		drawBlockLabels();
	};
}
