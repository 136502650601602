/**
 * Support old browsers (iOS 15 and older)
 * https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/roundRect#browser_compatibility
 *
 * Draws a rounded rectangle using the current state of the canvas.
 *
 * @param ctx
 * @param x The top left x coordinate
 * @param y The top left y coordinate
 * @param width The width of the rectangle
 * @param height The height of the rectangle
 * @param radius The corner radius
 */
const roundRect = (ctx, x, y, width, height, radius) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
};
// function used to bind the canvas context
export const roundRectPolyfill = (ctx) => {
    return (x, y, width, height, radius) => roundRect(ctx, x, y, width, height, radius);
};
