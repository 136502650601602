import { distanceSeatState, isSeatStateUnavailable, undistanceSeatState } from './seat-states';
import { distanceSeat, undistanceSeat } from './seats';
let distanceSeats = {};

export let distanceRange = 0;

export function setDistanceSeats(range, eventDistanceSeats) {
	distanceSeats = {};
	distanceRange = range || 0;

	if (distanceRange > 0) {
		for (const [seatId, linksJson] of Object.entries(eventDistanceSeats)) {
			distanceSeats[Number(seatId)] = JSON.parse(linksJson);
		}
	}
}

function isSeatAffected(seatId) {
	const linkedSeats = distanceSeats[seatId] || [];

	for (const linkedSeatId of linkedSeats) {
		if (isSeatStateUnavailable(linkedSeatId)) {
			return true;
		}
	}

	return false;
}

export function markDistanceSeats(seatId) {
	if (distanceRange < 1) {
		return;
	}

	const linkedSeats = (distanceSeats[seatId] || []).concat([seatId]);

	for (const linkedSeatId of linkedSeats) {
		if (isSeatAffected(linkedSeatId)) {
			distanceSeat(linkedSeatId);
			continue;
		}
		undistanceSeat(linkedSeatId);
	}
}

export function markDistanceSeatStates(seatId) {
	if (distanceRange < 1) {
		return;
	}

	const linkedSeats = (distanceSeats[seatId] || []).concat([seatId]);

	for (const linkedSeatId of linkedSeats) {
		if (isSeatAffected(linkedSeatId)) {
			distanceSeatState(linkedSeatId);
			continue;
		}
		undistanceSeatState(linkedSeatId);
	}
}
