var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
export const SessionTimerTag = 'session-timer';
let SessionTimer = class SessionTimer extends LitElement {
    constructor() {
        super(...arguments);
        this.expirationTime = 0;
        this.remainingTtl = 0;
        this.ttl = 0;
    }
    render() {
        let totalSeconds = this.remainingTtl;
        const hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        const timeLeft = (hours ? String(hours).padStart(2, '0') + ':' : '') + String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');
        return html `
			<div class="label">SESSION TIMEOUT</div>
			<div class="time">${timeLeft}</div>
		`;
    }
    willUpdate(changedProperties) {
        if (changedProperties.has('ttl')) {
            this.expirationTime = new Date().getTime() + this.ttl * 1000;
            this.remainingTtl = this.ttl;
            this.ttl = -1;
            clearInterval(this.timerId);
            if (this.remainingTtl <= 0) {
                return;
            }
            this.timerId = setInterval(() => {
                this.updateTimer();
            }, 1000);
        }
    }
    updateTimer() {
        this.remainingTtl = Math.floor((this.expirationTime - new Date().getTime()) / 1000);
        if (this.remainingTtl > 0) {
            return;
        }
        clearInterval(this.timerId);
        this.dispatchEventUp('onSessionTimeout');
    }
    // ***************************************** OTHER *****************************************
    dispatchEventUp(eventName, detail = {}) {
        this.dispatchEvent(new CustomEvent(eventName, {
            detail,
            bubbles: true,
            composed: true
        }));
    }
};
SessionTimer.styles = css `
		:host {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.label {
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 0.6px;
			font-size: 12px;
			color: var(--color-text-grey)
		}

		.time {
			font-size: 14px;
			color: var(--color-red-2)
		}
	`;
__decorate([
    property({ type: Number, state: true })
], SessionTimer.prototype, "remainingTtl", void 0);
__decorate([
    property({ type: Number })
], SessionTimer.prototype, "ttl", void 0);
SessionTimer = __decorate([
    customElement(SessionTimerTag)
], SessionTimer);
export { SessionTimer };
