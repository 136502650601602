var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { getPrivateMessages, getPublicMessages } from '../../messages';
import { HOLD, UNHOLD, getSeatState } from '../../seat-states';
export const SeatManagerTag = 'seat-manager';
const PLACEHOLDER_PUBLIC = 'This is a message the public will see';
const PLACEHOLDER_PRIVATE = 'This is a memo that only you can see';
const PLACEHOLDER_WARNING = 'Multiple selected';
let SeatManager = class SeatManager extends LitElement {
    constructor() {
        super(...arguments);
        this.isHidden = true;
        this.isHold = false;
        this.isUnhold = false;
        this.selectedSeatIds = [];
        this.isPublicWarning = false;
        this.isPrivateWarning = false;
        this.publicMessageValue = undefined;
        this.privateMessageValue = undefined;
        this.publicPlaceHolderValue = PLACEHOLDER_PUBLIC;
        this.privatePlaceHolderValue = PLACEHOLDER_PRIVATE;
        this.onPublicMessageChange = () => {
            this.publicMessageValue = this.publicMessage.value;
        };
        this.onPrivateMessageChange = () => {
            this.privateMessageValue = this.privateMessage.value;
        };
    }
    render() {
        return html `
		<label for="seat_status">Seat status</label>
		<div id="seat_status" class="toolbar">
			<button
				type="button"
				class="unhold-btn"
			  .disabled=${this.selectedSeatIds.length === 0}
				@click=${this.handleUnholdBtnClick}>
				<div class="seats">
					<div class="unhold"></div>
					<div class="unhold"></div>
					<div class="unhold"></div>
				</div>
				FOR SALE
			</button>

			<button
				type="button"
				class="onhond-btn"
				.disabled=${this.selectedSeatIds.length === 0}
				@click=${this.handleHoldBtnClick}>
				<div class="seats">
					<div class="unhold"></div>
					<div class="hold"></div>
					<div class="unhold"></div>
				</div>
				ON HOLD
			</button>
		</div>
		<label for="public_message">Public message</label>
		<textarea
			id="public_message"
			placeholder="${this.publicPlaceHolderValue}"
			autocomplete="off"
			name="public_message"
			.onchange=${this.onPublicMessageChange}
			.disabled=${this.selectedSeatIds.length === 0}></textarea>

		<label for="private_message">Internal memo</label>
		<textarea
			id="private_message"
			placeholder="${this.privatePlaceHolderValue}"
			autocomplete="off"
			name="private_message"
			.onchange=${this.onPrivateMessageChange}
			.disabled=${this.selectedSeatIds.length === 0}></textarea>
		`;
    }
    willUpdate(changedProperties) {
        if (changedProperties.has('selectedSeatIds')) {
            this.updateSummary();
        }
    }
    updated() {
        this.publicMessage.value = this.publicMessageValue || '';
        this.privateMessage.value = this.privateMessageValue || '';
    }
    handleUnholdBtnClick() {
        this.isHold = false;
        this.isUnhold = !this.isUnhold;
    }
    handleHoldBtnClick() {
        this.isUnhold = false;
        this.isHold = !this.isHold;
    }
    getParams() {
        const seatAction = this.isHold
            ? HOLD
            : (this.isUnhold ? UNHOLD : undefined);
        return {
            seatAction,
            publicMessage: this.publicMessageValue,
            privateMessage: this.privateMessageValue
        };
    }
    resetForm() {
        this.dispatchEventUp('onAllSeatsUnselect');
        this.resetSummary();
    }
    // ***************************************** OTHER *****************************************
    dispatchEventUp(eventName, detail = {}) {
        this.dispatchEvent(new CustomEvent(eventName, {
            detail,
            bubbles: true,
            composed: true
        }));
    }
    updateSummary() {
        if (this.selectedSeatIds.length === 0) {
            this.resetSummary();
            return;
        }
        const publicMessages = getPublicMessages(this.selectedSeatIds);
        this.isPublicWarning = this.isDifferentValues(publicMessages);
        this.publicMessageValue = this.isPublicWarning
            ? undefined
            : publicMessages[0] || this.publicMessageValue;
        this.publicPlaceHolderValue = this.isPublicWarning
            ? PLACEHOLDER_WARNING
            : PLACEHOLDER_PUBLIC;
        const privateMessages = getPrivateMessages(this.selectedSeatIds);
        this.isPrivateWarning = this.isDifferentValues(privateMessages);
        this.privateMessageValue = this.isPrivateWarning
            ? undefined
            : privateMessages[0] || this.privateMessageValue;
        this.privatePlaceHolderValue = this.isPrivateWarning
            ? PLACEHOLDER_WARNING
            : PLACEHOLDER_PRIVATE;
        const seatStates = this.selectedSeatIds.map(seatId => getSeatState(seatId).state);
        this.isHold = seatStates.every(state => state === HOLD);
        this.isUnhold = seatStates.every(state => state === undefined);
    }
    resetSummary() {
        this.isHold = false;
        this.isUnhold = false;
        this.isPublicWarning = false;
        this.isPrivateWarning = false;
        this.publicMessageValue = undefined;
        this.privateMessageValue = undefined;
        this.publicPlaceHolderValue = PLACEHOLDER_PUBLIC;
        this.privatePlaceHolderValue = PLACEHOLDER_PRIVATE;
    }
    isDifferentValues(values) {
        return values.some(value => value !== values[0]);
    }
};
SeatManager.styles = css `
		:host {
			width: 350px;
			background-color: #F2F4F9;
			font-family: Poppins;
			display: block;
		}

		:host([ishidden]) {
			display:none;
		}

		:host([isunhold]) .unhold-btn {
			border-color: #3D64FF;
			color: #3D64FF;
		}

		:host([ishold]) .onhond-btn {
			border-color: #3D64FF;
			color: #3D64FF;
		}

		.pressed{
			border-color: #3D64FF;
			color: #3D64FF;
		}

		label {
			display: block;
			padding: 20px 0 5px 0;
			margin-bottom: 0;
			color: #6F7287;
			font-size: 15px;
		}

		.toolbar {
			width: 333px;
			height: 120px;
		}

		button {
			font-weight: 600;
			width: 158px;
			height: 120px;
			vertical-align: middle;
			text-align: center;
			border-radius: 10px;
			border: 2px solid #E7E8ED;
			background-color: #fff;
			color:#6F7287
		}

		button:disabled {
			background-color: #f1f2f5;
		}

		.seats {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 auto 15px auto;
			width: 102px;
			height: 26px;
		}

		.onhond-btn{
			margin-left: 12px;
		}

		.hold,
		.unhold {
			border-radius: 13px;
			box-sizing: border-box;
			margin: 0 5px;
			width: 26px;
			height: 23px;
			border: 2px solid #007bff;
		}

		.unhold {
			background-color: #007bff;
		}

		button:disabled .hold,
		button:disabled .unhold {
			border-color: #6F7287;
		}

		button:disabled .unhold {
			background-color: #6F7287;
		}

		textarea {
			padding: 11px 10px;
			font-family: Poppins;
			width: 333px;
			height: 44px;
			box-sizing: border-box;
			border: 1px solid #E8E9EB;
			border-radius: 2px;
			resize: none;
		}

		textarea::placeholder {
			font-weight: 300;
 			color: var(--color-placeholder-text);
			padding: 0 0 0 7px;
		}

		:host([isPublicWarning]) textarea#public_message::placeholder {
			color: var(--color-accent);
		}

		:host([isPrivateWarning]) textarea#private_message::placeholder {
			color: var(--color-accent);
		}
	`;
__decorate([
    property({ type: Boolean })
], SeatManager.prototype, "isHidden", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], SeatManager.prototype, "isHold", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], SeatManager.prototype, "isUnhold", void 0);
__decorate([
    property({ type: Array })
], SeatManager.prototype, "selectedSeatIds", void 0);
__decorate([
    query('#public_message', true)
], SeatManager.prototype, "publicMessage", void 0);
__decorate([
    query('#private_message', true)
], SeatManager.prototype, "privateMessage", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], SeatManager.prototype, "isPublicWarning", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], SeatManager.prototype, "isPrivateWarning", void 0);
SeatManager = __decorate([
    customElement(SeatManagerTag)
], SeatManager);
export { SeatManager };
