var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getSeatStatePriceQuantities } from '../../seat-states';
import { classMap } from 'lit/directives/class-map.js';
import { checkSvg, triangleExclamationSvg } from '../icons';
export const PricesToChoosePanelTag = 'prices-to-choose-panel';
let PricesToChoosePanel = class PricesToChoosePanel extends LitElement {
    constructor() {
        super(...arguments);
        this.isHidden = true;
        this.isMobile = false;
        this.pricesToChoose = [];
        this.selectedSeatIds = [];
    }
    renderHeaderIcon(price) {
        if (price.classes.below) {
            return html `
				<div class="svg-icon">
					${checkSvg}
				</div>
			`;
        }
        if (price.classes.exact) {
            return html `
				<div class="svg-icon">
					${checkSvg}
				</div>
			`;
        }
        if (price.classes.above) {
            return html `
				<div class="svg-icon">
					${triangleExclamationSvg}
				</div>
			`;
        }
    }
    renderHeaderText(price) {
        if (price.classes.below && this.isMobile) {
            return html `
				<div>
					Please select <div class="accent">${price.quantity}</div> seats from <div class="accent">${price.name}</div>
				</div>
			`;
        }
        if (price.classes.below) {
            return html `
				<div>
					${price.quantitySelected} of ${price.quantity} selected
				</div>
			`;
        }
        if (price.classes.exact) {
            return html `
				<div>
					${price.quantitySelected} of ${price.quantity} selected
				</div>
			`;
        }
        if (price.classes.above) {
            return html `
				<div>
					Too many seats selected (${price.quantitySelected})
				</div>
			`;
        }
    }
    render() {
        return html `${this.pricesToChoose.map((price) => html `
				<div class=${classMap(price.classes)}>
					<div class="price-header">
						${this.renderHeaderIcon(price)}${this.renderHeaderText(price)}
					</div>
					<div class="price-body">
						Please select <div class="accent">${price.quantity}</div> seats from <div class="accent">${price.name}</div>
					</div>
				</div>
			`)}
		`;
    }
    willUpdate() {
        this.pricesToChoose = getSeatStatePriceQuantities(this.pricesToChoose || []);
        for (const price of this.pricesToChoose) {
            price.classes = {
                below: price.quantitySelected < price.quantity,
                exact: price.quantitySelected === price.quantity,
                above: price.quantitySelected > price.quantity,
                price: true
            };
        }
        this.isHidden = this.pricesToChoose.length === 0;
    }
};
PricesToChoosePanel.styles = css `
		:host{
			display: flex;
			flex-direction: column;
			justify-content: center;
			font-family: Inter;
			font-weight: 400;
		}

		:host([ismobile]){
			width: 100%;
		}

		:host([ishidden]){
			display: none;
		}

		.price {
			margin: 3px 0 0 0;
			font-size: 14px;
			border: none;
		}

		:host([ismobile]) .price {
			margin: 0;
		}


		.accent {
			display: inline-block;
			color: #3D64FF;
			font-weight: 600;
		}

		.price-header {
			height: 26px;
			box-sizing: border-box;
			border-radius: 10px 10px 0 0;
			color: #fff;
			text-align: center;
			line-height: 25px;
			display: flex;
			justify-content: center;

		}

		:host([ismobile]) .price-header{
			height: 36px;
			line-height: 36px;
			border-radius: 0;
			border: none;
		}

		:host([ismobile]) .below .price-header{
			background-color: #F1F4FF;
			border-radius: 0;
			color: #000833;
		}

		.svg-icon {
			padding: 2px 7px 0 0;
		}

		.below .svg-icon {
			color: rgba(255, 255, 255, 0.28);
		}

		.price-body {
			background-color: #fff;
			height: 36px;
			box-sizing: border-box;
			padding: 0 20px 0 20px;
			border-radius: 0 0 10px 10px;
			text-align: center;
			line-height: 35px;
		}

		:host([ismobile]) .price-body{
			display: none;
		}

		.below .price-header {
			background-color: #6F7287;
			border:#6F7287 2px solid;
		}

		.below .price-body {
			border:#6F7287 2px solid;
			box-shadow: 0px 4px 8px 0px rgba(118, 118, 118, 0.25);
		}

		.exact .price-header{
			background-color: #28C397;
			border:#28C397 2px solid;
		}

		.exact .price-body {
			border:#28C397 2px solid;
			box-shadow: 0px 4px 8px 0px rgba(26, 196, 153, 0.15);
		}

		.above .price-header {
			background-color: #F53F7B;
			border:#F53F7B 2px solid;
		}

		.above .price-body {
			border:#F53F7B 2px solid;
			box-shadow: 0px 4px 8px 0px rgba(253, 65, 128, 0.20);
		}
	`;
__decorate([
    property({ type: Boolean, reflect: true })
], PricesToChoosePanel.prototype, "isHidden", void 0);
__decorate([
    property({ type: Boolean })
], PricesToChoosePanel.prototype, "isMobile", void 0);
__decorate([
    property({ type: Array })
], PricesToChoosePanel.prototype, "pricesToChoose", void 0);
__decorate([
    property({ type: Array })
], PricesToChoosePanel.prototype, "selectedSeatIds", void 0);
PricesToChoosePanel = __decorate([
    customElement(PricesToChoosePanelTag)
], PricesToChoosePanel);
export { PricesToChoosePanel };
