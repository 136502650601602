import { drawImage } from './canvas/canvas-ctx';

let currentImages = [];
let _url = '';

export async function initImages(images, url) {
	currentImages = images || [];
	_url = url || '';

	return new Promise((resolve) => {
		loadImages(() => {
			resolve();
		});
	});
}

function loadImages(onAllLoaded) {
	let imagesIndex = currentImages.length;

	if (imagesIndex === 0) {
		return onAllLoaded();
	}

	const onload = () => {
		--imagesIndex === 0 && onAllLoaded();
	};

	const onerror = (err) => {
		console.error('Image load error #1652166252.', err);
		--imagesIndex === 0 && onAllLoaded();
	};

	currentImages.forEach(image => {
		const img = image.img = new Image;

		img.crossOrigin = 'anonymous';
		img.onload = onload;
		img.onerror = onerror;
		img.src = _url + image.path;
	});
}

export function drawImages() {
	currentImages
		.filter(image => !image.img || image.img.naturalHeight)
		.forEach(image => {
			drawImage(image);
		});
}
