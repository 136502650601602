import { getSeatStatePriceId } from './seat-states';
let _suborderSeatIds = [];
export function initSuborderSeats(seats) {
    _suborderSeatIds = seats || [];
}
export function getSuborderSeatIds() {
    return _suborderSeatIds;
}
export function isSuborderSeatId(seatId) {
    const suborderSeat = _suborderSeatIds.find(suborderSeatId => suborderSeatId === seatId);
    return suborderSeat !== undefined;
}
export function isSuborderSeat(seat) {
    const priceId = getSeatStatePriceId(seat.id);
    return priceId === seat.priceId && isSuborderSeatId(seat.id);
}
