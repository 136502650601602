import { drawTable } from './canvas/canvas-ctx';

let currentTables = [];

export function initTables(tables) {
	currentTables = tables || [];
}

export function drawTables() {
	currentTables.forEach(table => {
		drawTable(table);
	});
}
