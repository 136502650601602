import { css } from 'lit';
export const headerStyles = css `
	.header {
		height: 36px;
		display: flex;
		justify-content: center;
		text-align: center;
		border-radius:5px 5px 0px 0px;
		padding: 0 16px;

		font-size: 16px;
		font-weight: 500;
		line-height: 36px;
		letter-spacing: normal;
		color: var(--color-header-text);
		background-color: var(--color-header-background);
	}
`;
export const buttonStyles = css `
	button {
		font-family: 'Poppins', sans-serif;
		height: 45px;
		border-radius: 3px;
		text-transform: uppercase;
		letter-spacing: 1.2px;
		font-size: 16px;
		font-weight: 400;
		cursor: pointer;
		border: 1px solid var(--color-green-3);
		color: #fff;
		background-color: var(--color-green-3);
	}

	button:hover {
		background-color: #63C6A3;
	}
`;
