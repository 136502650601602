import { getMapSeat } from '../map-seats';
import { canModeShowOrderInfo } from '../mode';
import { getPriceLevelPrices } from '../prices';
import { getSeatStatePriceId, getSelectedSeatIds, isSeatStateSelectable, isSeatStateSelected, isSeatStateSold } from '../seat-states';
import { getLabelRangeSelectableSeatIds, selectSeats, clearSeatIdSelections, unselectSeats } from '../seats';
import { getSuborderSeatIds } from '../suborder-seats';

export class SeatsController {
	host;

	constructor(host) {
		this.host = host;
		this.lastSelectedSeatId = 0;

		host.addController(this);
		host.addEventListener('onAllSeatsUnselect', () => this.handleAllSeatsUnselect());
		host.addEventListener('onAreaSelect', this.handleAreaSelect);
		host.addEventListener('onSeatClick', (event) => this.handleSeatClick(event.detail.seatId, event.detail.isShiftKey));
		host.addEventListener('onSeatHover', (event) => this.handleSeatHover(event.detail.seatId, event.detail.hintLeft, event.detail.hintTop));
		host.addEventListener('onSelectionChange', () => this.handleSelectionChange());
		host.addEventListener('onSessionTimeout', () => this.handleSessionTimeout());
		host.addEventListener('onSuborderReset', this.handleSuborderReset);
	}

	// #region SETTERS/GETTERS
	get aseHeader() {
		return this.host.aseHeader;
	}

	get bottomTotal() {
		return this.host.bottomTotal;
	}

	get isMobile() {
		return this.host.isMobile;
	}

	get isPackage() {
		return this.host.packageId > 0;
	}

	get isSeatManagerMode() {
		return this.host.isSeatManagerMode;
	}

	get isSellMode() {
		return this.host.isSellMode;
	}

	get orderInfo() {
		return this.host.orderInfo;
	}

	get packageId() {
		return this.host.packageId;
	}

	get packageItemIndex() {
		return this.host.packageItemIndex;
	}

	get pricesDialog() {
		return this.host.pricesDialog;
	}

	get confirmDialog() {
		return this.host.confirmDialog;
	}

	get pricesToChoosePanel() {
		return this.host.pricesToChoosePanel;
	}

	get rightPanel() {
		return this.host.rightPanel;
	}

	get seatHint() {
		return this.host.seatHint;
	}

	get selectedSeats() {
		return this.host.selectedSeats;
	}

	get suborder() {
		return this.host.suborder;
	}
	// #endregion

	handleAllSeatsUnselect = () => {
		this.unselectSeats(getSelectedSeatIds());
	};

	handleSuborderReset = () => {
		const suborderSeatIds = getSuborderSeatIds();
		const selectedSeatIds = getSelectedSeatIds();
		const canceledSeatIds = suborderSeatIds.filter(seatId => !selectedSeatIds.includes(seatId));
		const newSeatIds = selectedSeatIds.filter(seatId => !suborderSeatIds.includes(seatId));
		const changedSeatIds = suborderSeatIds.filter(seatId => {
			const priceId = getSeatStatePriceId(seatId);
			if (priceId != this.suborder.priceId && selectedSeatIds.includes(seatId)) {
				return true;
			}
		});

		if (newSeatIds.length > 0 || changedSeatIds.length > 0) {
			this.unselectSeats([...newSeatIds, ...changedSeatIds]);
		}

		if (canceledSeatIds.length > 0 || changedSeatIds.length > 0) {
			this.selectSeats([...canceledSeatIds, ...changedSeatIds], this.suborder?.priceId || 0);
		}

		this.handleSelectionChange();
	};

	handleSeatHover = (seatId, hintLeft, hintTop) => {
		this.seatHint.seatId = seatId;
		this.seatHint.left = hintLeft;
		this.seatHint.top = hintTop;
	};

	// *************************************** SEAT CLICK HANDLERS **************************************
	handleSeatClick = (seatId, isShiftKey) => {
		this.seatHint.seatId = 0;

		if (seatId === 0) {
			return;
		}

		if (isSeatStateSelected(seatId)) {
			return this.handleSelectedSeatClick(seatId);
		}

		if (isSeatStateSelectable(seatId)) {
			return this.handleSelectableSeatClick(seatId, isShiftKey);
		}

		if (isSeatStateSold(seatId)) {
			return this.handleSoldSeatClick(seatId);
		}
	};

	handleSelectedSeatClick = (seatId) => {
		this.unselectSeats([seatId]);
	};

	handleSelectableSeatClick = async (seatId, isShiftKey) => {
		const mapSeat = getMapSeat(seatId);

		let priceId = 0;
		let selectableSeatIds = [seatId];

		if (!mapSeat.priceLevelId) {
			return false;
		}

		if (this.isSellMode && mapSeat.accessibleQuestion) {
			const isConfirmed = await this.confirmDialog.confirm(mapSeat.accessibleQuestion);

			if (!isConfirmed) {
				return false;
			}
		}

		// if (this.isSeatManagerMode) {
		// 	return this.selectSeats(selectableSeatIds, priceId);
		// }

		if (isShiftKey && this.lastSelectedSeatId) {
			selectableSeatIds = getLabelRangeSelectableSeatIds(this.lastSelectedSeatId, seatId);
			priceId = getSeatStatePriceId(this.lastSelectedSeatId);
		}

		if (!this.isSeatManagerMode) {
			if (priceId === 0) {
				priceId = await this.getSeatPriceId(seatId);
			}

			if (priceId === 0) {
				return false;
			}
		}

		this.lastSelectedSeatId = seatId;
		this.selectSeats(selectableSeatIds, priceId);
	};

	handleSoldSeatClick = (seatId) => {
		if (canModeShowOrderInfo()) {
			this.orderInfo.seatId = seatId;
		}
	};

	// ********************************************** OTHER HANDLERS *********************************************
	handleSelectionChange = () => {
		const selectedSeatIds = getSelectedSeatIds();

		this.selectedSeats.selectedSeatIds = selectedSeatIds;
		this.pricesToChoosePanel.selectedSeatIds = selectedSeatIds;
		this.aseHeader.selectedSeatIds = selectedSeatIds;
		this.bottomTotal.selectedSeatIds = selectedSeatIds;
		this.rightPanel.seatManager.selectedSeatIds = selectedSeatIds;
	};

	handleSessionTimeout = () => {
		clearSeatIdSelections(getSelectedSeatIds());
		this.handleSelectionChange();

		setTimeout(() => {
			alert('Session timeout. All seats released.');
		}, 50);

		if (!this.isSellMode) {
			return;
		}

		this.dispatchEventUp('onclose');
	};

	// ********************************************** OTHER *********************************************
	selectSeats = async (seatIds, priceId) => {
		await selectSeats(seatIds, this.packageId, this.packageItemIndex, priceId);
		this.handleSelectionChange();
	};

	unselectSeats = async (seatIds) => {
		unselectSeats(seatIds);
		this.handleSelectionChange();
	};

	getSeatPriceId = async (seatId) => {
		if (this.isSeatManagerMode) {
			return 0;
		}

		const seat = getMapSeat(seatId);
		const priceLevelsPrices = getPriceLevelPrices(seat.priceLevelId);

		if (priceLevelsPrices.length === 0) {
			const errorMessage = `Choose seat price error #1669102771. No price level prices. (seatId: ${seatId}, priceLevelId: ${seat.priceLevelId})`;
			console.error(errorMessage);
			return alert(errorMessage);
		}

		if (priceLevelsPrices.length === 1 && (!this.isMobile || this.isPackage)) {
			return priceLevelsPrices[0].id;
		}

		return await this.pricesDialog.choose(seatId, priceLevelsPrices);
	};

	dispatchEventUp = (eventName, detail = {}) => {
		this.host.dispatchEvent(new CustomEvent(eventName, {
			detail,
			bubbles: true,
			composed: true
		}));
	};
}
