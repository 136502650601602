import { simpleFetch } from './bus';
import { usMoney } from './formats';
import { canModeSellDisabledPrices, canModeSellHiddenPrices, canModeSellSoldOutPrices } from './mode';

/**
 * Array of prices
 * @type {{id: Number, priceLevelId: Number, price: Number, name: String}[]}
 */
let _prices = [];

export async function initPrices(prices, pricesToChoose = []) {
	const filteredPriceIds = pricesToChoose.map(price => price.id);

	_prices = [];
	prices.forEach(price => {
		if (price.isHidden && !canModeSellHiddenPrices() && pricesToChoose.length == 0) {
			return;
		}

		if (price.isSoldOut && !canModeSellSoldOutPrices()) {
			return;
		}

		if (price.isDisabled && !canModeSellDisabledPrices()) {
			return;
		}

		if (filteredPriceIds.length && !filteredPriceIds.includes(price.id)) {
			return;
		}

		_prices.push(price);
	});
}

export async function initXhrPrices(eventId, pricesToChoose = []) {
	const pricesJson = await simpleFetch(`/actions/ase/prices/read?event_id=${eventId}&include_hidden=1`);
	const pricesData = await pricesJson.json();
	const prices = pricesData.data;
	prices.forEach(price => {
		price.priceLevelId = Number(price.price_level_id);
	});
	initPrices(prices, pricesToChoose);
}

export function getPrice(priceId) {
	return _prices.find(price => price.id === priceId) || {};
}

export function getPrices() {
	return _prices;
}

export function getPriceLevelPrices(priceLevelId) {
	return _prices.filter(price => price.priceLevelId === priceLevelId);
}

export function getPriceLevelPriceRangeText(priceLevelId) {
	const prices = getPriceLevelPrices(priceLevelId).map(price => price.price);
	const minPrice = Math.min(...prices);
	const maxPrice = Math.max(...prices);

	if (prices.length === 0) {
		return '';
	}

	if (minPrice === maxPrice) {
		return usMoney(minPrice);
	}

	return `${usMoney(minPrice)} - ${usMoney(maxPrice)}`;
}

export function getPriceLevelPriceText(priceLevelId) {
	const prices = getPriceLevelPrices(priceLevelId).map(price => price.price);

	if (prices.length === 0) {
		return '';
	}

	if (prices.length == 1) {
		return 'Price: ' + usMoney(prices[0]);
	}

	return 'Price: ' + usMoney(Math.min(...prices)) + ' - ' + usMoney(Math.max(...prices));
}
