var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { eventDate } from '../formats';
import { getSuborderSeatIds } from '../suborder-seats';
import { getSeatStatePriceId, getSeatStatePriceQuantities } from '../seat-states';
import { buttonStyles } from './styles';
const CANCEL = 'CANCEL';
const CLOSE = 'CLOSE';
const CLOSE_MAP = 'CLOSE MAP';
const EXIT = 'EXIT';
const FINISHED = 'FINISHED';
const REASSIGN = 'REASSIGN';
const REMOVE_ALL = 'REMOVE ALL';
const RESET = 'RESET';
const SAVE = 'SAVE';
const TRANSFER = 'TRANSFER';
export const AseHeaderTag = 'ase-header';
let AseHeader = class AseHeader extends LitElement {
    constructor() {
        super(...arguments);
        this.eventName = '';
        this.eventStartTime = '';
        this.seatQuantity = 0;
        this.submitQuantity = 0;
        this.pricesToChoose = [];
        this.selectedSeatIds = [];
        this.suborderSeatIds = [];
        this.suborder = {};
        this.isGuestListMode = false;
        this.isReassignMode = false;
        this.isSeatManagerMode = false;
        this.isSellMode = false;
        this.isTransferMode = false;
        this.isMobile = false;
        this.isResetButtonDisabled = true;
        this.isSubmitButtonDisabled = false;
        this.isSubmitButtonGray = false;
        this.isSubmitButtonDarkGray = false;
        this.isSubmitButtonRed = false;
        this.resetButtonText = REMOVE_ALL;
        this.submitButtonText = FINISHED;
        this.handleSubmitClick = async () => {
            if (this.isTransferMode && this.seatQuantity > 0 && this.selectedSeatIds.length > this.seatQuantity) {
                return alert(`You are transferring only ${this.seatQuantity} seat(s)`);
            }
            if (this.isReassignMode && this.seatQuantity > 0 && this.selectedSeatIds.length > this.seatQuantity) {
                return alert(`You are reassigning only ${this.seatQuantity} seat(s)`);
            }
            if (this.pricesToChoose.length > 0 && this.selectedSeatIds.length > 0 && !this.pricesToChooseCanSubmit()) {
                return alert('Please select right amount of seats for each price');
            }
            this.dispatchEventUp('onSeatsSubmit', {
                submitQuantity: this.submitQuantity
            });
        };
        this.handleResetClick = () => {
            if (this.suborderSeatIds.length > 0) {
                return this.dispatchEventUp('onSuborderReset');
            }
            this.dispatchEventUp('onAllSeatsUnselect');
        };
        this.getChangedSeatQuantity = () => {
            return this.suborderSeatIds.filter(seatId => {
                const priceId = getSeatStatePriceId(seatId);
                if (priceId != this.suborder.priceId) {
                    return true;
                }
                return !this.selectedSeatIds.includes(seatId);
            }).length;
        };
        this.pricesToChooseCanSubmit = () => {
            for (const price of this.pricesToChoose) {
                if (price.quantitySelected !== price.quantity) {
                    return false;
                }
            }
            return true;
        };
        this.dispatchEventUp = (eventName, detail = {}) => {
            this.dispatchEvent(new CustomEvent(eventName, {
                detail,
                bubbles: true,
                composed: true
            }));
        };
    }
    render() {
        return html `
			<div class="title">
				<div class="event-name">
					${this.eventName}
				</div>
				<div class="event-date">
					${eventDate(this.eventStartTime)}
				</div>
			</div>

			<div class="toolbar">
				<button class="reset" ?disabled=${this.isResetButtonDisabled} @click=${this.handleResetClick}>
					${this.resetButtonText}
				</button>
				<button class="submit" ?disabled=${this.isSubmitButtonDisabled} @click=${this.handleSubmitClick}>
					<div class="submit-text">${this.submitButtonText}</div>
					<div class="submit-quantity">${this.submitQuantity}</div>
				</button>
			</div>
		`;
    }
    // #region SETTERS/GETTERS
    // #endregion
    updateSell() {
        this.submitQuantity = this.selectedSeatIds.length;
        this.isResetButtonDisabled = this.submitQuantity < 1;
        this.resetButtonText = REMOVE_ALL;
        this.submitButtonText = this.submitQuantity > 0 ? FINISHED : CLOSE;
        const pricesToChoose = getSeatStatePriceQuantities(this.pricesToChoose);
        if (this.submitQuantity < 1) {
            return;
        }
        for (const price of pricesToChoose) {
            if (price.quantitySelected !== price.quantity) {
                return this.isSubmitButtonGray = true;
            }
        }
    }
    updateGuestList() {
        const newSeatQuantity = this.selectedSeatIds.filter(seatId => !this.suborderSeatIds.includes(seatId)).length;
        const changedSeatQuantity = this.getChangedSeatQuantity();
        this.submitQuantity = this.selectedSeatIds.length;
        if (this.suborderSeatIds.length > 0) {
            this.isResetButtonDisabled = newSeatQuantity == 0 && changedSeatQuantity == 0;
        }
        else {
            this.isResetButtonDisabled = this.submitQuantity < 1;
        }
        this.resetButtonText = this.suborderSeatIds.length > 0 ? RESET : REMOVE_ALL;
        this.submitButtonText = this.submitQuantity > 0 ? FINISHED : CLOSE;
    }
    updateReassign() {
        const newSeatQuantity = this.selectedSeatIds.filter(seatId => !this.suborderSeatIds.includes(seatId)).length;
        const changedSeatQuantity = this.getChangedSeatQuantity();
        this.submitQuantity = newSeatQuantity > changedSeatQuantity ? newSeatQuantity : changedSeatQuantity;
        this.isResetButtonDisabled = this.submitQuantity < 1;
        this.isSubmitButtonGray = this.selectedSeatIds.length < this.seatQuantity && this.submitQuantity > 0;
        this.isSubmitButtonDarkGray = this.selectedSeatIds.length > this.seatQuantity;
        this.resetButtonText = RESET;
        this.submitButtonText = this.submitQuantity ? REASSIGN : CLOSE_MAP;
    }
    updateTransfer() {
        if (this.suborderSeatIds.length > 0) { // FROM SUBORDER
            this.submitQuantity = this.getChangedSeatQuantity();
            this.isResetButtonDisabled = this.submitQuantity < 1;
            this.resetButtonText = RESET;
            this.submitButtonText = this.submitQuantity ? TRANSFER : CLOSE_MAP;
        }
        else { // TO OTHER EVENT
            this.submitQuantity = this.selectedSeatIds.length;
            this.isSubmitButtonGray = this.selectedSeatIds.length < this.seatQuantity && this.submitQuantity > 0;
            this.isSubmitButtonDarkGray = this.selectedSeatIds.length > this.seatQuantity;
            this.resetButtonText = REMOVE_ALL;
            this.submitButtonText = this.submitQuantity ? SAVE : CANCEL;
        }
        this.isResetButtonDisabled = this.submitQuantity < 1;
    }
    updateSeatManager() {
        this.submitQuantity = this.selectedSeatIds.length;
        this.isResetButtonDisabled = this.submitQuantity < 1;
        this.resetButtonText = REMOVE_ALL;
        this.submitButtonText = this.submitQuantity > 0 ? SAVE : EXIT;
    }
    willUpdate() {
        this.isResetButtonDisabled = true;
        this.isSubmitButtonDisabled = false;
        this.isSubmitButtonGray = false;
        this.isSubmitButtonRed = false;
        this.suborderSeatIds = getSuborderSeatIds();
        if (this.isSellMode) {
            return this.updateSell();
        }
        else if (this.isReassignMode) {
            return this.updateReassign();
        }
        else if (this.isTransferMode) {
            return this.updateTransfer();
        }
        else if (this.isSeatManagerMode) {
            return this.updateSeatManager();
        }
        else if (this.isGuestListMode) {
            return this.updateGuestList();
        }
    }
};
AseHeader.styles = [
    buttonStyles,
    css `
		:host {
			align-items: center;
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			background-color: var(--color-header-background);
			color: var(--color-header-text);
			box-shadow: 0px 1px 6.6px 0.4px rgba(0, 0, 0, 0.1), 0 0 4.9px 0.1px rgba(0, 0, 0, 0.16);
			z-index: 1;
			user-select: none;
			font-family: Poppins;
		}

		.title {
			padding: 0 0 0 20px;
		}

		:host([ismobile]) .title {
			padding: 0 0 0 16px;
		}

		.event-name {
			font-size: 18px;
			line-height: 1.4;
			padding-top: 2px;
		}

		:host([ismobile]) .event-name {
			font-size: 16px;
			line-height: 1;
			padding-top: 4px;
		}

		.event-date {
			font-size: 13px;
			color: #dddddd;
		}

		:host([ismobile]) .event-date {
			font-size: 12px;
			padding-top: 2px;
		}

		.toolbar {
			min-width: 198px;
			display: flex;
		}

		:host([ismobile]) .toolbar {
			position: absolute;
			bottom: 0;
			width: 100%;
			background-color: var(--color-grey-24);
			padding-bottom: 10px;
			justify-content: center;
			display: flex;
		}

		button {
			width: 165px;
			border: none;
			color: var(--color-white);
		}

		.submit {
			background-color: var(--color-green-3);
			color: var(--color-white);
			margin-right: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		:host([isSubmitButtonRed]) .submit {
			background-color: var(--color-accent);
		}

		:host([isSubmitButtonGray]) .submit {
			background-color: #aaa;
		}

		:host([isSubmitButtonDarkGray]) .submit {
			background-color: #6e6f70;
			color: #aaa;
		}

		.submit:hover {
			background-color: #63C6A3;
		}

		.submit-quantity {
            background-color: #098562;
            color: var(--color-header-text);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
			margin-left: 10px;
            box-shadow: var(--shadow);
            font-size: 14px;
            font-weight: 500;
        }

		:host([isSubmitButtonGray]) .submit-quantity {
			background-color: #777;
		}

		:host([isSubmitButtonDarkGray]) .submit-quantity {
			background-color: #5e5f60;
			color: #aaa;
		}

		:host([isSubmitButtonRed]) .submit-quantity {
			background-color: #a33;
		}

		:host([submitQuantity="0"]) .submit-quantity {
			display: none;
		}


		:host([ismobile]) .submit {
			margin-right: 0;
		}

		.reset {
			background-color: var(--color-accent);
			margin-right: 18px;
		}

		.reset:disabled, .reset:disabled:hover {
			background-color: #6E6F70;
			color: #aaa;
			cursor: not-allowed;
		}

		.reset:hover {
			background-color: var(--color-accent-hover);
		}
	`
];
__decorate([
    property({ type: String })
], AseHeader.prototype, "eventName", void 0);
__decorate([
    property({ type: String })
], AseHeader.prototype, "eventStartTime", void 0);
__decorate([
    property({ type: Number })
], AseHeader.prototype, "seatQuantity", void 0);
__decorate([
    property({ type: Number, reflect: true })
], AseHeader.prototype, "submitQuantity", void 0);
__decorate([
    property({ type: Array })
], AseHeader.prototype, "pricesToChoose", void 0);
__decorate([
    property({ type: Array })
], AseHeader.prototype, "selectedSeatIds", void 0);
__decorate([
    property({ type: Array })
], AseHeader.prototype, "suborderSeatIds", void 0);
__decorate([
    property({ type: Object })
], AseHeader.prototype, "suborder", void 0);
__decorate([
    property({ type: Boolean })
], AseHeader.prototype, "isGuestListMode", void 0);
__decorate([
    property({ type: Boolean })
], AseHeader.prototype, "isReassignMode", void 0);
__decorate([
    property({ type: Boolean })
], AseHeader.prototype, "isSeatManagerMode", void 0);
__decorate([
    property({ type: Boolean })
], AseHeader.prototype, "isSellMode", void 0);
__decorate([
    property({ type: Boolean })
], AseHeader.prototype, "isTransferMode", void 0);
__decorate([
    property({ type: Boolean })
], AseHeader.prototype, "isMobile", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], AseHeader.prototype, "isResetButtonDisabled", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], AseHeader.prototype, "isSubmitButtonDisabled", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], AseHeader.prototype, "isSubmitButtonGray", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], AseHeader.prototype, "isSubmitButtonDarkGray", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], AseHeader.prototype, "isSubmitButtonRed", void 0);
__decorate([
    property({ type: String, state: true })
], AseHeader.prototype, "resetButtonText", void 0);
__decorate([
    property({ type: String, state: true })
], AseHeader.prototype, "submitButtonText", void 0);
AseHeader = __decorate([
    customElement(AseHeaderTag)
], AseHeader);
export { AseHeader };
