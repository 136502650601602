import { distanceRange, markDistanceSeatStates } from './distance-seats';
import { getMapSeat, getPriceLevelSeatIds } from './map-seats';
import { canModeSelectDistanceSeats, canModeSelectHoldSeats, canModeSelectSoldSeats, isBuyerUser } from './mode';
import { getPriceLevelsWithoutPrices } from './price-levels';
import { getPrice } from './prices';
import { isSuborderSeatId } from './suborder-seats';

export const HOLD = 'hold';
export const UNHOLD = 'unhold';

const SOLD = 'sold';
const LOCK = 'lock';
const NA = 'NA';
const isAnimated = 'isAnimated';
const isDistanced = 'isDistanced';
const isSelected = 'isSelected';
const isUnselected = 'isUnselected';

//{ state, isAnimated, isDistanced, isSelected, isUnselected, priceId }
let _seatStates = {};

export function initSeatStates(states, locks, selectedSeats, serverTime) {
	_seatStates = {};

	for (const seatIdStr in states) {
		const seatId = Number(seatIdStr);
		const state = states[seatId];

		if (state !== SOLD && state !== HOLD && state !== LOCK) {
			continue;
		}

		_seatStates[seatId] = { state };

		// suborder selected seat for reassign or transfer
		const seat = selectedSeats.find(seat => seat.seatId == seatId);
		if (seat && state == SOLD && canModeSelectSoldSeats()) {
			selectSeatState(seatId, seat.priceId);
		}
	}

	initSeatLocks(locks, serverTime, selectedSeats);
	// initSectionLocks(filterSectionIds);

	if (distanceRange > 0) {
		for (const seatId in _seatStates) {
			markDistanceSeatStates(seatId);
		}
	}
}

export function lockSeatsWithoutPrices() {
	const priceLevelsWithoutPrices = getPriceLevelsWithoutPrices();

	// TODO: unblock this
	priceLevelsWithoutPrices.forEach(priceLevel => {
		const priceLevelSeatIds = getPriceLevelSeatIds(priceLevel.id);

		priceLevelSeatIds.forEach(seatId => {
			const stateObj = _seatStates[seatId] || {};
			stateObj.state = NA;
			_seatStates[seatId] = stateObj;
		});
	});
}

function initSeatLocks(locks, serverTime, selectedSeats) {
	Object.keys(locks).forEach((seatIdStr) => {
		const seatId = Number(seatIdStr);
		const expirationTime = Number(locks[seatId]);

		//seat lock expired
		if (expirationTime < serverTime) {
			return;
		}

		// Regular selected seat || suborder selected seat for reassign or transfer
		const seat = selectedSeats.find(seat => seat.seatId == seatId);
		if (seat) {
			return selectSeatState(seat.seatId, seat.priceId);
		}

		const stateObj = _seatStates[seatId] || {};
		stateObj.state = LOCK;
		_seatStates[seatId] = stateObj;
	});
}

//************************************************** SEAT STATES *************************************************
export function getSeatState(seatId) {
	return _seatStates[seatId] || {};
}

function setState(seatId, state) {
	if (!_seatStates[seatId]) {
		_seatStates[seatId] = {};
	}

	_seatStates[seatId].state = state;
}

function delState(seatId) {
	if (_seatStates[seatId] && _seatStates[seatId].state) {
		delete _seatStates[seatId].state;
	}
}

function setAltState(seatId, stateName, stateValue) {
	if (!_seatStates[seatId]) {
		_seatStates[seatId] = {};
	}

	_seatStates[seatId][stateName] = stateValue;
}

function delAltState(seatId, stateName) {
	if (_seatStates[seatId] && _seatStates[seatId][stateName]) {
		delete _seatStates[seatId][stateName];
	}
}

export function selectSeatState(seatId, priceId = 0) {
	setAltState(seatId, isSelected, true);
	delAltState(seatId, isUnselected);

	if (priceId > 0) {
		_seatStates[seatId].priceId = priceId;
	}
}

export function unselectSeatState(seatId) {
	delAltState(seatId, isSelected);
	if (isSuborderSeatId(seatId)) {
		setAltState(seatId, isUnselected, true);
	}
}

export function holdSeatState(seatId) {
	return setState(seatId, HOLD);
}

export function unholdSeatState(seatId) {
	return delState(seatId);
}

export function sellSeatState(seatId) {
	return setState(seatId, SOLD);
}

export function unsellSeatState(seatId) {
	return delState(seatId);
}

export function lockSeatState(seatId) {
	return setState(seatId, LOCK);
}

export function unlockSeatState(seatId) {
	return delState(seatId);
}

export function distanceSeatState(seatId) {
	setAltState(seatId, isDistanced, true);
}

export function undistanceSeatState(seatId) {
	delAltState(seatId, isDistanced);
}

export function animateSeatState(seatId) {
	setAltState(seatId, isAnimated, true);
}

export function unanimateSeatState(seatId) {
	delAltState(seatId, isAnimated);
}

//************************************************** SEAT HINTS *************************************************
export function getHintSeatState(seatId) {
	const seatState = _seatStates[seatId] || {};

	if (isBuyerUser() && (seatState.isDistanced || seatState.state === SOLD || seatState.state === HOLD)) {
		return LOCK;
	}

	return seatState.state;
}

export function getDevSeatStateNotes(seatId) {
	const seatState = _seatStates[seatId] || {};
	const mapSeat = getMapSeat(seatId);

	return `seatId: ${seatId}, ` +
		`(x, y): (${mapSeat.x}, ${mapSeat.y}), ` +
		`sectionId: ${mapSeat.sectionId}, ` +
		`priceLevelId: ${mapSeat.priceLevelId}, ` +
		`state: ${seatState.state || 'available'}` +
		(seatState.isAnimated ? ', isAnimated: true' : '') +
		(seatState.isDistanced ? ', isDistanced: true' : '') +
		(seatState.isSelected ? ', isSelected: true' : '');
}

export function checkDrawUnavailableSeat(seatId) {
	const seatState = _seatStates[seatId] || {};

	return seatState.state === LOCK
		|| (seatState.state === SOLD && !seatState.isUnselected)
		|| seatState.isDistanced
		|| seatState.state === HOLD
		|| seatState.state === NA;
}

export function checkDrawSelectableHoldSeat(seatId) {
	return _seatStates[seatId]?.state === HOLD && canModeSelectHoldSeats();
}

//************************************************** "IS" SEAT STATES *************************************************
export function isSeatStateSelectable(seatId) {
	const seatState = _seatStates[seatId] || {};

	return !seatState.state ||
		(seatState.state === SOLD && canModeSelectSoldSeats() && isSuborderSeatId(seatId)) ||
		(seatState.state === HOLD && canModeSelectHoldSeats()) ||
		(seatState.isDistanced && canModeSelectDistanceSeats());
}

export function isSeatStateNa(seatId) {
	return _seatStates[seatId]?.state === NA;
}

export function isSeatStateSold(seatId) {
	return _seatStates[seatId]?.state === SOLD;
}

export function isSeatStateHold(seatId) {
	return _seatStates[seatId]?.state === HOLD;
}

export function isSeatStateLock(seatId) {
	return _seatStates[seatId]?.state === LOCK;
}

export function isSeatStateAnimated(seatId) {
	return _seatStates[seatId]?.isAnimated;
}

export function isSeatStateDistanced(seatId) {
	return _seatStates[seatId]?.isDistanced;
}

export function isSeatStateSelected(seatId) {
	return _seatStates[seatId]?.isSelected;
}

export function isSeatStateUnavailable(seatId) {
	const seatState = _seatStates[seatId] || {};

	return (seatState.state === SOLD || seatState.state === HOLD || seatState.state === LOCK);
}

//************************************************ OTHER *************************************************
export function getSelectedSeatIds() {
	return Object.keys(_seatStates)
		.filter(seatId => _seatStates[seatId].isSelected)
		.map(seatId => parseInt(seatId));
}

export function getSelectedPriceSeatIds(priceId) {
	return Object.keys(_seatStates)
		.filter(seatId => _seatStates[seatId].isSelected && _seatStates[seatId].priceId == priceId)
		.map(seatId => parseInt(seatId));
}

export function getSeatStatePriceId(seatId) {
	return _seatStates[seatId]?.priceId || 0;
}

export const getSeatStatePriceQuantities = (pricesToChoose) => {
	const priceQuantity = {};

	for (const seatId in _seatStates) {
		const seatState = _seatStates[seatId];
		if (seatState.isSelected) {
			priceQuantity[seatState.priceId] = priceQuantity[seatState.priceId] ? priceQuantity[seatState.priceId] + 1 : 1;
		}
	}

	const prices = [];

	for (const price of pricesToChoose) {
		const xPrice = getPrice(price.id);
		if (xPrice) {
			price.name = xPrice.name;
		}

		price.quantitySelected = priceQuantity[price.id] || 0;
		prices.push(price);
	}

	return prices;
};
