var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getMapSeatInfo } from '../../map-seats';
import { usMoney } from '../../formats';
import { headerStyles } from '../styles';
import { eyeSlashSvg } from '../icons';
export const PricesDialogTag = 'prices-dialog';
let PricesDialog = class PricesDialog extends LitElement {
    constructor() {
        super(...arguments);
        this.seatId = 0;
        this.prices = [];
        this._seatInfo = '';
    }
    renderPrice(price) {
        const priceName = price.isHidden || price.isSoldOut ? html `<div class="svg-icon" title="Not visible to customers">${eyeSlashSvg}</div><div class="price-name">${price.name}</div>` : html `<div class="price-name">${price.name}</div>`;
        return html `
			<div class="price">
				${priceName}
				<div class="price-value">${usMoney(price.price)}</div>
				<button type="button" @click=${() => this.handlePriceClick(price.id)} class="price-add">Add &gt;</button>
			</div>
		`;
    }
    render() {
        return html `
			<div class="container">
				<div class="header">${this._seatInfo}</div>
				<div class="body">
					${this.prices.map((price) => html `
						${this.renderPrice(price)}
					`)}
				</div>
				<div class="footer">
					<button type="button" class="close-btn" @click=${this.handleCloseClick} aria-label="Close">
						Close
					</button>
				</div>
			</div>
		`;
    }
    async choose(seatId, prices) {
        return new Promise((resolve) => {
            const seatInfo = getMapSeatInfo(seatId);
            this._resolve = resolve;
            this._seatInfo = seatInfo.seatInfo;
            this.seatId = seatId;
            this.prices = prices;
        });
    }
    handlePriceClick(priceId) {
        this.seatId = 0;
        this._resolve(priceId);
    }
    handleCloseClick() {
        this.seatId = 0;
        this._resolve(0);
    }
};
PricesDialog.styles = [
    headerStyles,
    css `
		:host {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.4);
			z-index: 100;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius:5px;
			font-family: Poppins;
		}

		:host([seatid="0"]) {
			display: none;
		}

		.container{
			background: var(--color-main-background);
			box-shadow: 0px 1px 9.4px 0.6px rgba(154, 154, 154, 0.24);
			border-radius: 5px;
			max-width: 380px;
			width: 100%;
		}

		.price button{
			border: none;
		}

		button{
			cursor: pointer;
		}

		.body {
			padding: 12px 0;
			background-color: var(--color-main-background);
		}

		.price {
			margin: 12px 0;
			display: flex;
			align-items: center;
			padding: 0 13px;
		}

		.price-name {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 500;
			color: #000833;
		}

		.price-value {
			margin-left: auto;
			font-size: 15px;
			font-weight: 600;
			color: var(--color-header-background);
			text-align: center;
		}

		.price-add {
			min-width: 65px;
			margin-left: 20px;
			background-color: var(--color-accent);
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 1.8px;
			color: var(--color-white);
			padding: 7px 0;
		}

		.footer {
			padding: 6px 0px 15px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		button{
			border-radius: 3px;
		}

		.close-btn{
			width: 135px;
			height: 40px;
			box-sizing: border-box;
			border-radius: 20px;
			border: 1px solid var(--color-green-3);
			background: none;
			color: var(--color-green-3);
			text-transform: uppercase;
		}

		.svg-icon {
			display: inline-block;
			padding: 5px 6px 0 0;
		}

		svg {
			width: 16px;
			height: 16px;
			color: #999;
		}
	`
];
__decorate([
    property({ type: Number, reflect: true })
], PricesDialog.prototype, "seatId", void 0);
__decorate([
    property({ type: Array })
], PricesDialog.prototype, "prices", void 0);
PricesDialog = __decorate([
    customElement(PricesDialogTag)
], PricesDialog);
export { PricesDialog };
