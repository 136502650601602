var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { BottomTotalTag } from './bottom-total';
import { SessionTimerTag } from './session-timer';
import { PricingLegendTag } from './pricing-legend';
import { SeatManagerTag } from './seat-manager';
import { SelectedSeatsTag } from './selected-seats';
export const RightPanelTag = 'right-panel';
let RightPanel = class RightPanel extends LitElement {
    constructor() {
        super(...arguments);
        this.isMobile = false;
        this.isSelectedSeatsHidden = false;
        this.isSeatManagerMode = false;
        this.isTransferMode = false;
        this.uniqueVisitorId = '';
        this.mode = '';
        this.selectedSeatQuantity = 0;
    }
    render() {
        return html `
			<div class="right-top-panel">
				<pricing-legend ?ishidden=${this.isSeatManagerMode} ?isMobile=${this.isMobile}>	</pricing-legend>
				<seat-manager ?ishidden=${!this.isSeatManagerMode}>	</seat-manager>
				<selected-seats ?isSeatManagerMode=${this.isSeatManagerMode}> </selected-seats>
			</div>

			<div class="right-bottom-panel">
				<session-timer></session-timer>
				<bottom-total
					?isSeatManagerMode=${this.isSeatManagerMode}
					?isTransferMode=${this.isTransferMode}>
				</bottom-total>
			</div>
		`;
    }
    // #region SETTERS/GETTERS
    get selectedSeatIds() {
        return this.selectedSeats.selectedSeatIds;
    }
    // #endregion
    willUpdate(changedProperties) {
        if (changedProperties.has('isMobile')) {
            this.isSelectedSeatsHidden = this.isMobile;
        }
    }
    handleSeatCounterClick() {
        this.isSelectedSeatsHidden = !this.isSelectedSeatsHidden;
    }
};
RightPanel.styles = css `
		:host {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background-color: var(--color-grey-24);
			box-shadow: 0px 1px 6.6px 0.4px rgba(0, 0, 0, 0.1), 0 0 4.9px 0.1px rgba(0, 0, 0, 0.16);
			z-index: 1;
			user-select: none;
			width: 380px;
			min-width:380px;
			max-width: 380px;
		}

		:host([ismobile]) {
			width:0;
			min-width: 0;
		}

		.right-top-panel {
			padding: 0 15px 70px 15px;
			height: calc(100% - 167px);
			overflow-y: auto;
			overflow-x: hidden;
		}

		.right-bottom-panel {
			height: 70px;
			border-top: 2px solid var(--color-accent);
		}

		:host([ismobile]) selected-seats{
			position: absolute;
			top: 50px;
			left: 0;
			right: 0;
			width: 100%;
			height: auto;
		}

		:host([isselectedseatshidden]) selected-seats{
			display: none;
		}

		:host([ismobile]) .right-bottom-panel{
			position: absolute;
			bottom: 55px;
			left: 0;
			right: 0;
			width: 100%;
			background-color: var(--color-grey-24);
		}

		session-timer {
			padding: 8px 25px 0;
		}
	`;
__decorate([
    property({ type: Boolean, reflect: true })
], RightPanel.prototype, "isMobile", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], RightPanel.prototype, "isSelectedSeatsHidden", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], RightPanel.prototype, "isSeatManagerMode", void 0);
__decorate([
    property({ type: Boolean })
], RightPanel.prototype, "isTransferMode", void 0);
__decorate([
    property({ type: String })
], RightPanel.prototype, "uniqueVisitorId", void 0);
__decorate([
    property({ type: String, reflect: true })
], RightPanel.prototype, "mode", void 0);
__decorate([
    property({ type: Number })
], RightPanel.prototype, "selectedSeatQuantity", void 0);
__decorate([
    query(BottomTotalTag, true)
], RightPanel.prototype, "bottomTotal", void 0);
__decorate([
    query(PricingLegendTag, true)
], RightPanel.prototype, "pricingLegend", void 0);
__decorate([
    query(SeatManagerTag, true)
], RightPanel.prototype, "seatManager", void 0);
__decorate([
    query(SelectedSeatsTag, true)
], RightPanel.prototype, "selectedSeats", void 0);
__decorate([
    query(SessionTimerTag, true)
], RightPanel.prototype, "sessionTimer", void 0);
RightPanel = __decorate([
    customElement(RightPanelTag)
], RightPanel);
export { RightPanel };
