var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { buttonStyles } from '../styles';
export const ConfirmDialogTag = 'confirm-dialog';
let ConfirmDialog = class ConfirmDialog extends LitElement {
    constructor() {
        super(...arguments);
        this.isHidden = true;
        this._confirmQuestion = '';
    }
    render() {
        return html `
			<div class="container">
				<div class="header">Confirmation</div>
				<div class="body">
					<div class="title">${this._confirmQuestion}</div>
				</div>
				<div class="footer">
					<button class="cancel" @click=${this.handleCancelClick}>Cancel</button>
					<button @click=${this.handleSubmitClick}>Proceed</button>
				</div>
			</div>
		`;
    }
    async confirm(confirmQuestion) {
        this.isHidden = false;
        this._confirmQuestion = confirmQuestion;
        return new Promise((resolve) => {
            this._resolve = resolve;
        });
    }
    handleCancelClick() {
        this.isHidden = true;
        this._resolve(false);
    }
    handleSubmitClick() {
        this.isHidden = true;
        this._resolve(true);
    }
};
ConfirmDialog.styles = [
    buttonStyles,
    css `
		:host {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.4);
			z-index: 100;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius:5px;
			font-family: Poppins;
		}

		:host([ishidden]){
			display: none;
		}

		.container {
			background: var(--color-main-background);
			box-shadow: 0px 1px 9.4px 0.6px rgba(154, 154, 154, 0.24);
			border-radius: 5px;
			max-width: 380px;
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		.header {
			padding: 20px 35px 0 35px;
  		font-family: Poppins;
			font-size: 18px;
			color: var(--color-title);
		}

		.body {
			padding: 5px 35px 0 35px;
			display: flex;
			flex-direction: column;
		}

		.title {
			margin-top: 15px;
			color: #707388;
			font-size: 16px;
		}

		.footer {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 30px 35px 30px 35px;
		}

		button {
			width: 48%;
		}

		.cancel {
			color: var(--color-green-3);
			background: none;
		}

		.cancel:hover {
			color: #63C6A3;
			background: none;
		}
	`
];
__decorate([
    property({ type: Boolean, reflect: true })
], ConfirmDialog.prototype, "isHidden", void 0);
ConfirmDialog = __decorate([
    customElement(ConfirmDialogTag)
], ConfirmDialog);
export { ConfirmDialog };
