import { fetchCallback, postData, putData } from '../bus';
import { setPrivateMessages, setPublicMessages } from '../messages';
import { HOLD, UNHOLD, getSelectedSeatIds } from '../seat-states';
import { getCartSeatInfo, holdSeat, unholdSeat } from '../seats';
import { getSuborderSeatIds, initSuborderSeats, isSuborderSeat } from '../suborder-seats';

export class SubmitsController {
	host;

	constructor(host) {
		this.host = host;
		host.addController(this);
		host.addEventListener('onSeatsSubmit', (event) => this.handleSubmit(event), true);
		host.addEventListener('onReassignSubmit', (event) => this.handleReassignSubmit(event), true);
		host.addEventListener('onAseToGaTransfer', (event) => this.handleAseToGaTransfer(event), true);
		host.addEventListener('onAseToAseTransfer', (event) => this.handleAseToAseTransfer(event), true);
	}

	// #region SETTERS/GETTERS
	get aseHeader() {
		return this.host.aseHeader;
	}

	get eventId() {
		return this.host.eventId;
	}

	set eventId(value) {
		this.host.eventId = value;
	}

	get isGuestListMode() {
		return this.host.isGuestListMode;
	}

	get isReassignMode() {
		return this.host.isReassignMode;
	}

	get isSellMode() {
		return this.host.isSellMode;
	}

	get isSeatManagerMode() {
		return this.host.isSeatManagerMode;
	}

	get isTransferMode() {
		return this.host.isTransferMode;
	}

	get mode() {
		return this.host.mode;
	}

	get progressSpinner() {
		return this.host.progressSpinner;
	}

	get rightPanel() {
		return this.host.rightPanel;
	}

	get seatHint() {
		return this.host.seatHint;
	}

	get seatManager() {
		return this.rightPanel.seatManager;
	}

	get sessionTimer() {
		return this.rightPanel.sessionTimer;
	}

	get submitData() {
		return this.host.submitData;
	}

	set submitData(value) {
		this.host.submitData = value;
	}

	get suborder() {
		return this.host.suborder || null;
	}

	set suborder(value) {
		this.host.suborder = value;
	}

	get transferDialog() {
		return this.host.transferDialog;
	}

	get userType() {
		return this.host.userType;
	}
	// #endregion

	// ***************************************** HANDLE EVENTS *****************************************
	handleSubmit = (event) => {
		if (event.detail.submitQuantity === 0) {
			return this.dispatchEventUp('onsubmit', { seats: [] });
			// return this.dispatchEventUp('onclose');
		}

		const selectedSeatIds = getSelectedSeatIds();

		if (this.isReassignMode) {
			return this.submitReassign(selectedSeatIds);
		}

		if (this.isTransferMode) {
			return this.submitTransfer(selectedSeatIds);
		}

		if (this.isSeatManagerMode) {
			return this.submitSeatManager(selectedSeatIds);
		}

		if (this.isGuestListMode) {
			return this.submitGuestList(selectedSeatIds);
		}

		this.submitSell(selectedSeatIds.map(getCartSeatInfo));
	};

	handleAseToAseTransfer = (event) => {
		const submitQuantity = this.aseHeader.submitQuantity;
		this.submitData = {
			fromSuborderId: this.suborder.id,
			notes: event.detail.notes,
			seatQuantity: submitQuantity,
			seats: getSelectedSeatIds().map(seatId => ({
				seatId,
				eventId: this.eventId,
				suborderId: this.suborder.id
			}))
		};

		this.suborder = null;
		this.eventId = event.detail.eventId;
		initSuborderSeats([]);
	};

	handleAseToGaTransfer = async (event) => {
		const selectedSeatIds = getSelectedSeatIds();
		const suborderSeatIds = getSuborderSeatIds();
		const params = {
			userType: this.userType,
			fromSuborderId: this.suborder.id,
			toEventId: event.detail.eventId,
			toPriceId: event.detail.priceId,
			toQuantity: suborderSeatIds.length - selectedSeatIds.length,
			seats: selectedSeatIds.map(seatId => ({
				seatId,
				eventId: this.eventId,
				suborderId: this.suborder.id
			}))
		};

		const responseJson = await postData('/ase3/actions/transfer-seats.php', params);

		if (!fetchCallback(responseJson)) {
			return false;
		}

		this.dispatchEventUp('onsubmit', responseJson);
	};

	// ******************************************** SUBMITS ********************************************
	submitSell = (seats) => {
		console.log('submitSell seats', JSON.parse(JSON.stringify(seats)));
		this.dispatchEventUp('onsubmit', { seats, ttl: this.sessionTimer.remainingTtl });
	};

	submitReassign = async (selectedSeatIds) => {
		const seats = selectedSeatIds.map(getCartSeatInfo);
		const params = {
			userType: this.userType,
			fromSuborderId: this.suborder.id,
			seats: seats.map(seat => {
				const ret = {
					seatId: seat.id,
					eventId: this.eventId
				};

				if (isSuborderSeat({ id: seat.id, priceId: this.suborder.priceId })) {
					ret.suborderId = this.suborder.id;
					return ret;
				}

				ret.priceId = seat.priceId;
				return ret;
			})
		};

		const responseJson = await postData('/ase3/actions/transfer-seats.php', params);

		if (!fetchCallback(responseJson)) {
			return false;
		}

		this.dispatchEventUp('onsubmit', responseJson);
	};

	submitTransfer = async (selectedSeatIds) => {
		if (this.suborder) {
			this.transferDialog.isHidden = false;
			this.seatHint.seatId = 0;

			return;
		}

		const params = JSON.parse(JSON.stringify(this.submitData));
		const seats = selectedSeatIds.map(getCartSeatInfo);

		params.userType = this.userType;
		params.seats = params.seats || [];
		seats.map((seat) => {
			params.seats.push({
				seatId: seat.id,
				priceId: seat.priceId,
				eventId: this.eventId
			});
		});

		const responseJson = await postData('/ase3/actions/transfer-seats.php', params);

		if (!fetchCallback(responseJson)) {
			return false;
		}

		this.dispatchEventUp('onsubmit', responseJson);
	};

	submitSeatManager = async (selectedSeatIds) => {
		const params = this.seatManager.getParams();
		params.eventId = this.eventId;
		params.seatIds = selectedSeatIds;
		params.userType = this.userType;
		params.mode = this.mode;
		const headers = {
			'x-pp-user-type': this.userType
		};
		const responseJson = await putData(`/v3/ase3/events/${this.eventId}/seats`, params, headers);

		if (!fetchCallback(responseJson)) {
			return false;
		}

		if (params.publicMessage !== undefined) {
			setPublicMessages(params.seatIds, params.publicMessage);
		}

		if (params.privateMessage !== undefined) {
			setPrivateMessages(params.seatIds, params.privateMessage);
		}

		if (params.seatAction === HOLD) {
			selectedSeatIds.forEach(seatId => holdSeat(seatId));
		}

		if (params.seatAction === UNHOLD) {
			selectedSeatIds.forEach(seatId => unholdSeat(seatId));
		}

		this.seatManager.resetForm();

		return true;
	};

	submitGuestList = async (selectedSeatIds) => {
		const seats = selectedSeatIds.map(getCartSeatInfo);
		const params = this.submitData || {};

		params.eventId = this.eventId;
		params.seats = seats.map(seat => ({
			seatId: seat.id,
			priceLevelId: seat.priceLevelId,
			priceId: seat.priceId,
			eventId: this.eventId
		}));

		if (this.suborder) {
			params.suborderId = this.suborder.id;
		}

		const responseJson = await postData('/ase3/actions/confirm-guestlist.php', params);

		if (!fetchCallback(responseJson)) {
			return;
		}

		this.dispatchEventUp('onsubmit', responseJson);
	};

	// ***************************************** OTHER *****************************************
	dispatchEventUp = (eventName, detail = {}) => {
		// if (!this.isSellMode) {
		this.sessionTimer.ttl = 0;
		// }

		this.host.dispatchEvent(new CustomEvent(eventName, {
			detail,
			bubbles: true,
			composed: true
		}));
	};
}
