var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { getMapSeatInfo } from '../../map-seats';
import { getPriceLevelFirstPrice, getPriceLevelPriceQuantity } from '../../price-levels';
import { getPrice } from '../../prices';
import { getSeatStatePriceId } from '../../seat-states';
import { unselectSeats } from '../../seats';
import { getSection } from '../../sections';
import { trashSvg } from '../icons';
import { usMoney } from '../../formats';
export const SelectedSeatsTag = 'selected-seats';
let SelectedSeats = class SelectedSeats extends LitElement {
    constructor() {
        super(...arguments);
        this.isSeatManagerMode = false;
        this.selectedSeatIds = [];
        this.seatData = [];
    }
    renderPriceLevel(priceLevel) {
        const price = priceLevel.isPriceVisible ? `<div class="price-level-price">${usMoney(priceLevel.price)}</div>` : '';
        return html `
			<div class="price-level" style="background-color:${priceLevel.color}">
				<div class="price-level-name">${priceLevel.name}</div>
				${unsafeHTML(price)}
			</div>
			${priceLevel.sections.map((section) => html `
				${this.renderSection(priceLevel, section)}
			`)}
		`;
    }
    renderSection(priceLevel, section) {
        const sectionName = priceLevel.name !== section.name && section.name != '' ? `<div class="section-name">${section.name}</div>` : '';
        return html `
			${unsafeHTML(sectionName)}
			${section.prices.map((price) => html `
				${this.renderPrice(priceLevel, price)}
			`)}
		`;
    }
    renderPrice(priceLevel, price) {
        const priceHtml = priceLevel.isPriceVisible ? '' : html `
			<div class="price">
				<div class="price-name">
					${price.name}
				</div>
				<div class="price-price">
					${usMoney(price.price)}
				</div>
			</div>
		`;
        return html `
			${priceHtml}
			${price.seats.map((seat) => html `
				${this.renderSeat(seat)}
			`)}
		`;
    }
    renderSeat(seat) {
        return html `
			<div class="seat">
				<div class="seat-info">
					${seat.seatInfo}
				</div>
				<button type="button" class="btn-remove" @click=${() => this.handleRemoveClick(seat.id)} aria-label="remove">
					<span class="visually-hidden" aria-label="remove">Remove seat</span>${trashSvg}
				</button>
			</div>
		`;
    }
    render() {
        return html `
			${this.seatData.map((priceLevel) => html `
				<div class="price-level-container">
					${this.renderPriceLevel(priceLevel)}
				</div>
			`)}
		`;
    }
    handleRemoveClick(seatId) {
        unselectSeats([seatId]);
        this.dispatchEvent(new CustomEvent('onSelectionChange', {
            detail: { seatId },
            bubbles: true,
            composed: true
        }));
    }
    willUpdate(changedProperties) {
        if (changedProperties.has('selectedSeatIds')) {
            this.updateSelectedSeats();
        }
    }
    updateSelectedSeats() {
        const seatData = [];
        const seats = this.selectedSeatIds.map(getMapSeatInfo);
        for (const seat of seats) {
            const seatPriceId = getSeatStatePriceId(seat.id);
            let priceLevel;
            priceLevel = seatData.find((priceLevel) => priceLevel.priceLevelId === seat.priceLevelId);
            if (!priceLevel) {
                const price = getPriceLevelFirstPrice(seat.priceLevelId);
                const priceQuantity = getPriceLevelPriceQuantity(seat.priceLevelId);
                priceLevel = {
                    name: seat.priceLevelName,
                    color: seat.color,
                    isPriceVisible: false,
                    price: undefined,
                    priceLevelId: seat.priceLevelId,
                    sections: []
                };
                if (priceQuantity < 2 && priceLevel.name == price.name) {
                    priceLevel.price = price.price;
                    priceLevel.isPriceVisible = true;
                }
                seatData.push(priceLevel);
            }
            let section = priceLevel.sections.find((section) => section.id === seat.sectionId);
            if (!section) {
                const seatSection = getSection(seat.sectionId);
                section = {
                    id: seat.sectionId,
                    name: seatSection.name,
                    prices: []
                };
                priceLevel.sections.push(section);
            }
            let price = section.prices.find((price) => price.id === seatPriceId);
            if (!price) {
                const seatPrice = getPrice(seatPriceId);
                price = {
                    id: seatPrice.id,
                    name: seatPrice.name,
                    price: seatPrice.price,
                    seats: []
                };
                section.prices.push(price);
            }
            price.seats.push({ id: seat.id, seatInfo: seat.seatInfo });
        }
        this.seatData = seatData;
    }
};
SelectedSeats.styles = css `
		:host {
			background-color: var(--color-grey-24);
			height: 100%;
		}

		.price-level-container{
			border: solid 1px var(--color-grey-25);
			border-radius: 3px;
			background-color: #fff;
			margin-top: 18px;
			padding-bottom: 8px;
		}

		.price-level {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 8px;
		}

		.price-level-name{
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			color: #fff;
			padding: 0 0 0 21px;
			height: 26px;
			line-height: 26px;
		}

		.price-level-price {
			font-size: 14px;
			font-weight: 600;
			color: #fff;
			padding: 0 26px 0 21px;
			height: 26px;
			line-height: 26px;
		}

		.section-name{
			font-weight: Bold;
			font-size: 12px;
			text-transform: uppercase;
			padding: 6px 0px 0px 21px;
			color: #215BB1;
		}

		.price {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px 0 2px 21px;
			color: #000833;
		}

		.price-name{
			color: #333;
			font-size: 14px;
			font-weight: 500;
		}

		.price-price {
			font-size: 14px;
			font-weight: Medium;
			color: var(--color-grey-18);
			padding-right: 26px;
		}

		.seat {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 0 3px 21px;
		}

		.seat-info {
			font-size: 13px;
			color: var(--color-text-grey);
		}

		.btn-remove{
			border: none;
			width: 22px;
			height: 22px;
			border-radius: 3px;
			background-color: var(--color-white);
			cursor: pointer;
			padding-right: 49px;
		}

		.btn-remove svg{
			color: var(--color-accent);
		}

		.btn-remove svg:hover {
			color: var(--color-accent-hover);
		}

		.visually-hidden:not(:focus):not(:active) {
			clip: rect(0 0 0 0);
			clip-path: inset(100%);
			height: 1px;
			overflow: hidden;
			position: absolute;
			white-space: nowrap;
			width: 1px;
		}

		:host([isseatmanagermode]) .price-price{
			display: none;
		}
	`;
__decorate([
    property({ type: Boolean, reflect: true })
], SelectedSeats.prototype, "isSeatManagerMode", void 0);
__decorate([
    property({ type: Array })
], SelectedSeats.prototype, "selectedSeatIds", void 0);
__decorate([
    property({ type: Array, state: true })
], SelectedSeats.prototype, "seatData", void 0);
SelectedSeats = __decorate([
    customElement(SelectedSeatsTag)
], SelectedSeats);
export { SelectedSeats };
