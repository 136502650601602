const _usMoneyFormat = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: 2
});

const _eventDateFormat = new Intl.DateTimeFormat('en-US', {
	weekday: 'long',
	year: 'numeric',
	month: 'short',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric'
});

const _shortEventDateFormat = new Intl.DateTimeFormat('en-US', {
	weekday: 'short',
	year: '2-digit',
	month: 'short',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric'
});

const getDaySuffix = (day) => {
	if (day >= 11 && day <= 13) {
		return 'th';
	}

	switch (day % 10) {
		case 1: return 'st';
		case 2: return 'nd';
		case 3: return 'rd';
		default: return 'th';
	}
};

export const usMoney = (price) => {
	return _usMoneyFormat.format(price);
};

export const eventDate = (eventDateStr) => {
	if (!eventDateStr) {
		return '';
	}

	const eventDate = new Date(eventDateStr);
	const parts = _eventDateFormat.formatToParts(eventDate);
	const day = parts.find(part => part.type === 'day').value;
	const suffix = getDaySuffix(day);
	let isDateTimeSeparator = false;
	let isAmPmSeparator = false;

	const formattedDate = parts.map(part => {
		if (part.type === 'day') {
			return { type: 'day', value: `${day}${suffix}` };
		}

		if (part.type === 'year') {
			isDateTimeSeparator = true;
		}

		if (part.type === 'literal' && isDateTimeSeparator) {
			isDateTimeSeparator = false;
			return { type: 'literal', value: ' - ' };
		}

		if (part.type === 'minute') {
			isAmPmSeparator = true;
		}

		if (part.type === 'literal' && isAmPmSeparator) {
			isAmPmSeparator = false;
			return { type: 'literal', value: '' };
		}

		if (part.type === 'dayPeriod') {
			return { type: 'dayPeriod', value: part.value.toLowerCase() };
		}

		return part;
	}).map(part => part.value)
		.join('');

	return formattedDate;
};

export const shortEventDate = (eventDateStr) => {
	if (!eventDateStr) {
		return '';
	}

	const eventDate = new Date(eventDateStr);
	const parts = _shortEventDateFormat.formatToParts(eventDate);
	const day = parts.find(part => part.type === 'day').value;
	const suffix = getDaySuffix(day);
	let isYearSeparator = false;
	let isDateTimeSeparator = false;
	let isAmPmSeparator = false;

	const formattedDate = parts.map(part => {
		if (part.type === 'day') {
			isYearSeparator = true;
			return { type: 'day', value: `${day}${suffix}` };
		}

		if (part.type === 'literal' && isDateTimeSeparator) {
			isDateTimeSeparator = false;
			return { type: 'literal', value: ' @ ' };
		}
		if (part.type === 'year') {
			isDateTimeSeparator = true;
		}

		if (part.type === 'literal' && isYearSeparator) {
			isYearSeparator = false;
			return { type: 'literal', value: ' \'' };
		}

		if (part.type === 'minute') {
			isAmPmSeparator = true;
		}

		if (part.type === 'literal' && isAmPmSeparator) {
			isAmPmSeparator = false;
			return { type: 'literal', value: '' };
		}

		if (part.type === 'dayPeriod') {
			return { type: 'dayPeriod', value: part.value.toLowerCase() };
		}

		return part;
	}).map(part => part.value)
		.join('');

	return formattedDate;
};
