var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getPrice } from '../../prices';
import { getSeatStatePriceId } from '../../seat-states';
import { usMoney } from '../../formats';
export const BottomTotalTag = 'bottom-total';
let BottomTotal = class BottomTotal extends LitElement {
    constructor() {
        super(...arguments);
        this.isHidden = false;
        this.isSeatManagerMode = false;
        this.isTransferMode = false;
        this.selectedSeatIds = [];
        this.selectedSeatQuantity = 0;
        this.totalAmount = 0;
    }
    render() {
        return html `
			<div class="label">
				TOTAL x${this.selectedSeatQuantity}
			</div>
			<div class="price">
				${usMoney(this.totalAmount)}
			</div>
		`;
    }
    willUpdate(changedProperties) {
        this.isHidden = this.isSeatManagerMode || this.isTransferMode;
        if (changedProperties.has('selectedSeatIds')) {
            this.calculateTotalAmount();
        }
    }
    calculateTotalAmount() {
        let totalAmount = 0;
        for (const seatId of this.selectedSeatIds) {
            const seatPriceId = getSeatStatePriceId(seatId);
            const seatPrice = getPrice(seatPriceId);
            totalAmount += seatPrice.price;
        }
        this.totalAmount = totalAmount;
        this.selectedSeatQuantity = this.selectedSeatIds.length;
    }
};
BottomTotal.styles = css `
		:host {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 4px 25px 0;
		}

		:host([ishidden]){
			display: none;
		}

		.label, .price {
			font-size: 18px;
			line-height: 1;
			font-weight: 600;
			color: var(--color-text-grey);
		}

		.label {
			letter-spacing: 1.8px;
		}
	`;
__decorate([
    property({ type: Boolean, reflect: true })
], BottomTotal.prototype, "isHidden", void 0);
__decorate([
    property({ type: Boolean })
], BottomTotal.prototype, "isSeatManagerMode", void 0);
__decorate([
    property({ type: Boolean })
], BottomTotal.prototype, "isTransferMode", void 0);
__decorate([
    property({ type: Array })
], BottomTotal.prototype, "selectedSeatIds", void 0);
__decorate([
    property({ type: Number, reflect: true })
], BottomTotal.prototype, "selectedSeatQuantity", void 0);
__decorate([
    property({ type: Number, reflect: true })
], BottomTotal.prototype, "totalAmount", void 0);
BottomTotal = __decorate([
    customElement(BottomTotalTag)
], BottomTotal);
export { BottomTotal };
