import { emitSelectSeats, emitUnselectSeats } from './bus';
import { drawSeat, drawAnimatedSeat } from './canvas/canvas-ctx';
import { getLabelRangeMapSeats, getMapSeat, getMapSeatInfo, getPriceLevelText } from './map-seats';
import { getPriceLevel } from './price-levels';
import { getPrice, getPriceLevelPriceText } from './prices';
import {
	animateSeatState,
	distanceSeatState,
	getSeatState,
	holdSeatState,
	isSeatStateAnimated,
	isSeatStateSelectable,
	lockSeatState,
	selectSeatState,
	sellSeatState,
	unanimateSeatState,
	undistanceSeatState,
	unholdSeatState,
	unlockSeatState,
	unselectSeatState,
	unsellSeatState
} from './seat-states';
import { getSection } from './sections';

const animatingSeats = [];

let _blinkTimer;

// ********************************************* SEAT ACTIONS *********************************************
export const setSeatIdSelections = (seatIds, priceId) => {
	for (const seatId of seatIds) {
		const seat = getMapSeat(seatId);
		animateSeat(seatId, false);

		if (!seat) {
			return console.error('Seat select error #1652166225. Seat not found. SeatId:', seatId);
		}

		selectSeatState(seatId, priceId);
		drawSeat(seat);
		unanimateSeatState(seatId);
	}
};

export const selectSeats = async (seatIds, packageId, packageItemIndex, priceId) => {
	setSeatIdSelections(seatIds, priceId);
	await emitSelectSeats(seatIds, packageId, packageItemIndex, priceId);
};

export const clearSeatIdSelections = (seatIds) => {
	for (const seatId of seatIds) {
		const seat = getMapSeat(seatId);
		animateSeat(seatId, true);

		if (!seat) {
			return console.error('Seat unselect error #1652164620. Seat not found. SeatId:', seatId);
		}

		unselectSeatState(seatId);
		drawSeat(seat);
		unanimateSeatState(seatId);
	}
};

export const unselectSeats = async (seatIds) => {
	clearSeatIdSelections(seatIds);
	await emitUnselectSeats(seatIds);
};

export function lockSeat(seatId) {
	const seat = getMapSeat(seatId);

	if (!seat) {
		return console.error('Seat lock error #1651172429. Seat not found. seatId:', seatId);
	}

	lockSeatState(seatId);
	drawSeat(seat);
}

export function unlockSeat(seatId) {
	const seat = getMapSeat(seatId);

	if (!seat) {
		return console.error('Seat unlock error #1651172398. Seat not found. seatId:', seatId);
	}

	unlockSeatState(seatId);
	drawSeat(seat);
}

export function holdSeat(seatId) {
	const seat = getMapSeat(seatId);

	if (!seat) {
		return console.error('Seat hold error #1651172126. Seat not found. seatId:', seatId);
	}

	holdSeatState(seatId);
	drawSeat(seat);
}

export function unholdSeat(seatId) {
	const seat = getMapSeat(seatId);

	if (!seat) {
		return console.error('Seat unhold error #1651177058. Seat not found. seatId:', seatId);
	}

	unholdSeatState(seatId);
	drawSeat(seat);
}

export function sellSeat(seatId) {
	const seat = getMapSeat(seatId);

	if (!seat) {
		return console.error('Seat sell error #1650903114. Seat not found. seatId:', seatId);
	}

	sellSeatState(seatId);
	drawSeat(seat);
}

export function unsellSeat(seatId) {
	const seat = getMapSeat(seatId);

	if (!seat) {
		return console.error('Seat unsell error #1651171699. Seat not found. seatId:', seatId);
	}

	unsellSeatState(seatId);
	drawSeat(seat);
}

export function distanceSeat(seatId) {
	const seat = getMapSeat(seatId);

	if (!seat) {
		return console.error('Seat distance error #1651172179. Seat not found. seatId:', seatId);
	}

	distanceSeatState(seatId);
	drawSeat(seat);
}

export function undistanceSeat(seatId) {
	const seat = getMapSeat(seatId);

	if (!seat) {
		console.error('Seat undistance error #1651171702. Seat not found. seatId:', seatId);
	}

	undistanceSeatState(seatId);
	drawSeat(seat);
}

// ********************************************* SEAT ANIMATION *********************************************
function animate() {
	for (const [seatId, seat] of Object.entries(animatingSeats)) {
		if (!isSeatStateAnimated(seatId) && (Date.now() - seat.animationStartTime + 250) % 500 < 50) {
			drawSeat(seat);
			delete animatingSeats[seatId];
			continue;
		}

		drawAnimatedSeat(seat);
	}

	if (Object.keys(animatingSeats).length < 1) {
		cancelAnimationFrame(_blinkTimer);
		_blinkTimer = false;
		return;
	}

	_blinkTimer = requestAnimationFrame(animate.bind(this));
}

export function animateSeat(seatId, isUnselect) {
	const seat = getMapSeat(seatId);

	animateSeatState(seatId);
	animatingSeats[seatId] = Object.assign({
		id: seatId,
		color: seat.color,
		animationStartTime: Date.now(),
		isUnselect
	}, seat);

	if (!_blinkTimer) {
		_blinkTimer = requestAnimationFrame(animate.bind(this));
	}
}

// ********************************************* SEAT INFO *********************************************
export function getCartSeatInfo(seatId) {
	const mapSeat = getMapSeat(seatId);
	const mapSeatInfo = getMapSeatInfo(seatId);
	const seatState = getSeatState(seatId);

	return {
		id: mapSeat.id,
		priceId: seatState.priceId,

		color: mapSeat.color,

		priceLevelId: mapSeatInfo.priceLevelId,
		priceLevelName: mapSeatInfo.priceLevelName,
		priceLevelText: getPriceLevelText(mapSeat.priceLevelId, mapSeat.sectionId),

		priceText: getPriceLevelPriceText(mapSeat.priceLevelId),

		seatInfo: mapSeatInfo.seatInfo
	};
}

export function getSeatDetails(seatId) {
	const mapSeat = getMapSeat(seatId);
	const section = getSection(mapSeat.sectionId);
	const priceLevel = getPriceLevel(mapSeat.priceLevelId);
	const seatState = getSeatState(seatId);
	const price = getPrice(seatState.priceId);

	return {
		id: mapSeat.id,
		color: mapSeat.color,
		seatInfo: mapSeat.seatInfo,

		priceId: seatState.priceId,
		priceName: price.name,

		priceLevelId: mapSeat.priceLevelId,
		priceLevelName: priceLevel.name,
		priceLevelText: getPriceLevelText(mapSeat.priceLevelId, mapSeat.sectionId),

		sectionId: mapSeat.sectionId,
		sectionName: section.name
	};
}

// ********************************************* RANGE SEATS *********************************************
export function getLabelRangeSelectableSeatIds(seatId1, seatId2) {
	return getLabelRangeMapSeats(seatId1, seatId2)
		.map(seat => seat.id)
		.filter(seatId => isSeatStateSelectable(seatId));
}
