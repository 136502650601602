var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { magnifierSvg } from '../icons';
export const ScaleInputTag = 'scale-input';
let ScaleInput = class ScaleInput extends LitElement {
    constructor() {
        super(...arguments);
        this.minScale = 0.1;
        this.maxScale = 2;
        this.scale = this.minScale;
        this._scaleRange = this.maxScale - this.minScale;
        this._step = this._scaleRange / 1000;
        this._scale = this.scale;
    }
    render() {
        return html `
			<input type="range" class="slider" part="slider" min=${this.minScale} max="${this.maxScale}" step=${this._step}
				@input=${this.handleInput} />
			<button class="scale-btn scale-up-btn" @click=${this.handleScaleUpClick}>${magnifierSvg}</button>
			<button class="scale-btn scale-down-btn" @click=${this.handleScaleDownClick}>${magnifierSvg}</button>
		`;
    }
    willUpdate() {
        this._scaleRange = this.maxScale - this.minScale;
        this._step = this._scaleRange / 100;
    }
    updated(updatedProperties) {
        if (updatedProperties.has('scale')) {
            this.setInputScale(this.scale);
        }
    }
    handleInput(event) {
        const input = event.currentTarget;
        this._scale = Number(input.value);
        this.style.setProperty('--progress-percent', `${((this._scale - this.minScale) / this._scaleRange) * 100}%`);
        this.fireScaleChange(this._scale);
    }
    handleScaleUpClick() {
        this.setInputScale(Math.min(this.maxScale, this._scale + this._step * 25));
        this.fireScaleChange(this._scale);
    }
    handleScaleDownClick() {
        this.setInputScale(Math.max(this.minScale, this._scale - this._step * 25));
        this.fireScaleChange(this._scale);
    }
    setInputScale(scale) {
        this._scale = scale;
        this._input.value = this._scale.toString();
        this.style.setProperty('--progress-percent', `${((this._scale - this.minScale) / this._scaleRange) * 100}%`);
    }
    fireScaleChange(scale) {
        this.dispatchEvent(new CustomEvent('onScaleChange', {
            bubbles: true,
            composed: true,
            detail: { scale }
        }));
    }
};
ScaleInput.styles = css `
		:host {
			--width: 28px;
			--height: 111px;

			--thumb-border: #fff;
			--thumb-color: var(--color-accent);
			--thumb-size: 28px;

			--track-height: 111px;
			--track-width: 10px;
			--track-radius: 10px;
			--track-color: var(--color-regular);

			--progress-color: var(--color-accent);

			display: inline-block;
			position: relative;
			margin-right: calc(var(--width) / 2);

			width: 55px;
			height: 227px;
			display: block;
		}

		button {
			cursor: pointer;
		}

		.scale-btn{
			position: absolute;
			left: 1px;
			border: none;
			background: none;
			width: 53px;
			height: 48px;
			border:1px solid transparent;
			border-radius:2px;
		}

		.scale-btn:hover{
			border-color: var(--color-regular);
			box-shadow:0px 4px 8px rgba(0, 0, 0, 0.08);
		}

		.scale-btn svg {
			color: var(--color-regular);
			width: 30px;
			height: 30px;
			left:11px;
			top:8px;
			position: absolute;
		}

		.scale-up-btn{
			top: 1px;
		}

		.scale-up-btn:after {
			content: '+';
			display: block;
			color: var(--color-accent);
			font-size: 20px;
			left:17px;
			top:9px;
			position: absolute;
			/* width: 15px; */
			/* height: 15px; */
		}

		.scale-down-btn{
			bottom: 0;
		}

		.scale-down-btn:after {
			content: '-';
			display: block;
			color: var(--color-accent);
			font-size: 24px;
			left:19px;
			top:4px;
			position: absolute;
			/* width: 3px; */
			/* height: 3px; */
		}

		.slider {
			-webkit-appearance: none;
			width: var(--height);
			height: var(--width);
			left: 12px;
			bottom: 42px;
			transform: rotate(-90deg) translateY(calc(var(--width) / 2));
			transform-origin: left;
			position: absolute;
		}

		/* https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/ */
		/* Special styling for WebKit/Blink */
		.slider::-webkit-slider-thumb {
			-webkit-appearance: none;

			cursor: pointer;
			height: var(--thumb-size);
			width: var(--thumb-size);
			border-radius: 50%;
			border: 4px solid var(--thumb-border);
			margin-top: calc((var(--thumb-size) / -2 + var(--track-width) / 2));
			background-color: var(--thumb-color);
			box-shadow: -4px 0 3px rgba(96, 96, 96, 0.25);
		}

		/* All the same stuff for Firefox */
		.slider::-moz-range-thumb {
			cursor: pointer;
			height: var(--thumb-size);
			width: var(--thumb-size);
			border-radius: 50%;
			border: 4px solid var(--thumb-border);
			margin-top: calc((var(--thumb-size) / -2 + var(--track-width) / 2));
			background-color: var(--thumb-color);
			box-shadow: -4px 0 3px rgba(96, 96, 96, 0.25);
		}

		.slider::-webkit-slider-runnable-track {
			background: linear-gradient(to right, var(--progress-color) 0%, var(--progress-color) var(--progress-percent), var(--track-color) var(--progress-percent), var(--track-color) 100%);

			width: var(--track-height);
			height: var(--track-width);
			background-color: var(--track-color);
			outline: none;
			border-radius: var(--track-radius);
			cursor: pointer;
		}

		.slider::-moz-range-track {
			width: var(--track-height);
			height: var(--track-width);
			background-color: var(--track-color);
			outline: none;
			border-radius: var(--track-radius);
			cursor: pointer;
		}

		.slider::-moz-range-progress {
			width: var(--track-height);
			height: var(--track-width);
			background-color: var(--progress-color);
			border-radius: var(--track-radius);
		}
	`;
__decorate([
    property({ type: Number })
], ScaleInput.prototype, "minScale", void 0);
__decorate([
    property({ type: Number })
], ScaleInput.prototype, "maxScale", void 0);
__decorate([
    property({ type: Number })
], ScaleInput.prototype, "scale", void 0);
__decorate([
    property({ type: Number, state: true })
], ScaleInput.prototype, "_step", void 0);
__decorate([
    query('input[type="range"]', true)
], ScaleInput.prototype, "_input", void 0);
ScaleInput = __decorate([
    customElement(ScaleInputTag)
], ScaleInput);
export { ScaleInput };
