var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import 'lit-media-query/lit-media-query.js';
import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { EventsController } from './ase-map-events-controller';
import { SeatsController } from './ase-map-seats-controller';
import { SubmitsController } from './ase-map-submits-controller';
import { initMode, initUserType, isGuestListMode, isReassignMode, isSeatManagerMode, isSellMode, isTransferMode } from '../mode';
import { getSelectedPriceSeatIds, getSelectedSeatIds } from '../seat-states';
import { getSeatDetails } from '../seats';
import { MapPanelTag } from './map-panel/map-panel';
import { RightPanelTag } from './right-panel/right-panel';
import { SeatHintTag } from './seat-hint';
import { PricesDialogTag } from './dialogs/prices-dialog';
import { OrderInfoTag } from './dialogs/order-info';
import { AseHeaderTag } from './ase-header';
import { InstructionDialogTag } from './dialogs/instruction-dialog';
import { ConfirmDialogTag } from './dialogs/confirm-dialog';
import { ProgressSpinnerTag } from './progress-spinner';
import { TransferDialogTag } from './dialogs/transfer-dialog';
export const AseMapTag = 'ase-map';
let AseMap = class AseMap extends LitElement {
    constructor() {
        super(...arguments);
        this.events = new EventsController(this);
        this.seats = new SeatsController(this);
        this.submits = new SubmitsController(this);
        this.isMobile = false;
        this.isMobileAlways = false;
        this.isGuestListMode = false;
        this.isReassignMode = false;
        this.isSellMode = false;
        this.isSeatManagerMode = false;
        this.isTransferMode = false;
        this.enableAreaSelect = false;
        this.eventId = 0;
        this.packageId = 0;
        this.packageItemIndex = 0;
        this.url = '';
        this.uniqueVisitorId = '';
        this.mode = '';
        this.pricesToChoose = [];
        this.suborder = null;
        this.submitData = null;
        this.query = '(max-width: 992px)';
    }
    // #region SETTERS/GETTERS
    get canvas() {
        return this.mapPanel.canvas;
    }
    get pricesToChoosePanel() {
        return this.mapPanel.pricesToChoosePanel;
    }
    get pricingLegend() {
        return this.rightPanel.pricingLegend;
    }
    get bottomTotal() {
        return this.rightPanel.bottomTotal;
    }
    get selectedSeats() {
        return this.rightPanel.selectedSeats;
    }
    get sessionTimer() {
        return this.rightPanel.sessionTimer;
    }
    render() {
        return html `
			<ase-header
				?isMobile=${this.isMobile}
				?isGuestListMode=${this.isGuestListMode}
				?isReassignMode=${this.isReassignMode}
				?isSellMode=${this.isSellMode}
				?isSeatManagerMode=${this.isSeatManagerMode}
				?isTransferMode=${this.isTransferMode}
			>
			</ase-header>
			<div class="body">
				<map-panel ?isMobile=${this.isMobile}> </map-panel>
				<right-panel
					?isMobile=${this.isMobile}
					?isSeatManagerMode=${this.isSeatManagerMode}
					?isTransferMode=${this.isTransferMode}
					mode=${this.mode}
				>
				</right-panel>
			</div>

			<lit-media-query
				.query="${this.query}"
				@changed="${this.handleMediaQuery}"
			>
			</lit-media-query>
			<progress-spinner> </progress-spinner>
			<seat-hint ?isMobile=${this.isMobile}> </seat-hint>
			<order-info ?isMobile=${this.isMobile} eventId=${this.eventId}>
			</order-info>
			<prices-dialog> </prices-dialog>
			<transfer-dialog eventId=${this.eventId}> </transfer-dialog>
			<instruction-dialog> </instruction-dialog>
			<confirm-dialog> </confirm-dialog>
		`;
    }
    willUpdate(changedProperties) {
        if (changedProperties.has('isMobileAlways') && this.isMobileAlways) {
            this.isMobile = this.isMobileAlways;
        }
    }
    async updated(changedProperties) {
        if (changedProperties.has('eventId') && this.eventId > 0) {
            setTimeout(() => {
                this.initEvent();
            }, 0);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaQuery(event) {
        this.isMobile = event.detail.value || this.isMobileAlways;
    }
    async initEvent() {
        this.progressSpinner.isHidden = false;
        initUserType(this.userType);
        initMode(this.mode);
        this.isReassignMode = isReassignMode(this.mode);
        this.isSeatManagerMode = isSeatManagerMode(this.mode);
        this.isSellMode = isSellMode(this.mode);
        this.isTransferMode = isTransferMode(this.mode);
        this.isGuestListMode = isGuestListMode(this.mode);
        this.aseHeader.pricesToChoose = this.pricesToChoose || [];
        this.pricesToChoosePanel.pricesToChoose = this.pricesToChoose || [];
        this.aseHeader.seatQuantity = this.submitData?.seatQuantity || this.suborder?.seatQuantity || 0;
        this.aseHeader.suborder = this.suborder || {};
        const mapInfo = await this.events.init();
        this.events.drawMap();
        this.mapPanel.initMap(mapInfo.width, mapInfo.height, mapInfo.backgroundColor);
        this.pricingLegend.eventId = this.eventId;
        this.seats.handleSelectionChange();
        this.progressSpinner.isHidden = true;
    }
    unselectPriceSeats(priceId = 0) {
        if (priceId === 0) {
            return this.seats.unselectSeats(getSelectedSeatIds());
        }
        this.seats.unselectSeats(getSelectedPriceSeatIds(priceId));
    }
    getSeatDetails(seatId) {
        return getSeatDetails(seatId);
    }
};
// #endregion
AseMap.styles = css `
		:host {
			--color-accent: #f53f7b;
			--color-accent-hover: #f95b85;
			--color-header-background: #2f353f;
			--color-header-text: #fff;
			--color-placeholder-text: #a2a2a2;

			--color-main-background: #fff;
			--color-white: #fff;
			--color-regular: #d2d2d2;
			--color-purple-6: #7953e6;

			--color-title: #2e3280;
			--color-text-grey: #6f7297;

			--color-grey-4: #dddddd;
			--color-grey-6: #63686e;
			--color-grey-9: #cccccc;
			--color-grey-23: #b3b7bf;
			--color-grey-24: #f5f6fa;
			--color-grey-25: #e7e8ed;
			--color-grey-30: #d2cddb;

			--color-green-3: #28c397;
			--color-green-4: #128467;

			--color-red-2: #ff0000;

			font-family: 'Poppins', sans-serif;
			line-height: 1.5;

			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			flex-direction: column;
			transition: opacity 0.3s ease-in-out;
			overflow: hidden;
		}

		ase-header {
			width: 100%;
			height: 60px;
			min-height: 60px;
			max-height: 60px;
		}

		.body {
			display: flex;
			flex-direction: row;
			flex: 1;
			overflow: hidden;
		}

		map-panel {
			flex: 1;
			height: 100%;
		}
	`;
__decorate([
    property({ type: Boolean, reflect: true, state: true })
], AseMap.prototype, "isMobile", void 0);
__decorate([
    property({ type: Boolean })
], AseMap.prototype, "isMobileAlways", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], AseMap.prototype, "isGuestListMode", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], AseMap.prototype, "isReassignMode", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], AseMap.prototype, "isSellMode", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], AseMap.prototype, "isSeatManagerMode", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], AseMap.prototype, "isTransferMode", void 0);
__decorate([
    property({ type: Boolean })
], AseMap.prototype, "enableAreaSelect", void 0);
__decorate([
    property({ type: Number })
], AseMap.prototype, "eventId", void 0);
__decorate([
    property({ type: Number })
], AseMap.prototype, "packageId", void 0);
__decorate([
    property({ type: Number })
], AseMap.prototype, "packageItemIndex", void 0);
__decorate([
    property({ type: String })
], AseMap.prototype, "url", void 0);
__decorate([
    property({ type: String })
], AseMap.prototype, "uniqueVisitorId", void 0);
__decorate([
    property({ type: String })
], AseMap.prototype, "mode", void 0);
__decorate([
    property({ type: String })
], AseMap.prototype, "userType", void 0);
__decorate([
    property({ type: Array })
], AseMap.prototype, "pricesToChoose", void 0);
__decorate([
    property({ type: Object })
], AseMap.prototype, "suborder", void 0);
__decorate([
    property({ type: Object })
], AseMap.prototype, "submitData", void 0);
__decorate([
    property({ type: String })
], AseMap.prototype, "query", void 0);
__decorate([
    query(AseHeaderTag, true)
], AseMap.prototype, "aseHeader", void 0);
__decorate([
    query(MapPanelTag, true)
], AseMap.prototype, "mapPanel", void 0);
__decorate([
    query(RightPanelTag, true)
], AseMap.prototype, "rightPanel", void 0);
__decorate([
    query(SeatHintTag, true)
], AseMap.prototype, "seatHint", void 0);
__decorate([
    query(PricesDialogTag, true)
], AseMap.prototype, "pricesDialog", void 0);
__decorate([
    query(InstructionDialogTag, true)
], AseMap.prototype, "instructionDialog", void 0);
__decorate([
    query(ConfirmDialogTag, true)
], AseMap.prototype, "confirmDialog", void 0);
__decorate([
    query(TransferDialogTag, true)
], AseMap.prototype, "transferDialog", void 0);
__decorate([
    query(OrderInfoTag, true)
], AseMap.prototype, "orderInfo", void 0);
__decorate([
    query(ProgressSpinnerTag, true)
], AseMap.prototype, "progressSpinner", void 0);
AseMap = __decorate([
    customElement(AseMapTag)
], AseMap);
export { AseMap };
