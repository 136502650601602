const SEAT_MANAGER = 'SEAT_MANAGER';
const TRANSFER_SEATS = 'TRANSFER_SEATS';
const REASSIGN_SEATS = 'REASSIGN_SEATS';
const SELL = 'SELL';
const GUEST_LIST = 'GUEST_LIST';


const BO = 'BO';
const PROMOTER = 'PROMOTER';
const BUYER = 'BUYER';

let _mode = '';
let _userType = '';

export const initMode = (mode) => {
	_mode = mode || SELL;
};

export const initUserType = (userType) => {
	_userType = userType || BUYER;
};

export const getMode = () => {
	return _mode;
};

// ***************************************************** IS MODES *****************************************************
export const isSellMode = (mode = '') => {
	if (mode != '') {
		return mode === SELL;
	}
	return _mode === SELL;
};

export const isGuestListMode = (mode = '') => {
	if (mode != '') {
		return mode === GUEST_LIST;
	}
	return _mode === GUEST_LIST;
};

export const isTransferMode = (mode = '') => {
	if (mode != '') {
		return mode === TRANSFER_SEATS;
	}
	return _mode === TRANSFER_SEATS;
};

export const isReassignMode = (mode = '') => {
	if (mode != '') {
		return mode === REASSIGN_SEATS;
	}
	return _mode === REASSIGN_SEATS;
};

export const isSeatManagerMode = (mode = '') => {
	if (mode != '') {
		return mode === SEAT_MANAGER;
	}
	return _mode === SEAT_MANAGER;
};

// **************************************************** USER TYPES ****************************************************
export const getUserType = () => {
	return _userType;
};

export const isPromoterUser = () => {
	return _userType === PROMOTER;
};

export const isBoUser = () => {
	return _userType === BO;
};

export const isBuyerUser = () => {
	return _userType === BUYER;
};

// **************************************************** CAN ****************************************************
export const canModeSelectHoldSeats = () => {
	return _userType === BO ||
		_userType === PROMOTER;
};

export const canModeSelectSoldSeats = () => {
	return _mode === GUEST_LIST
		|| _mode === REASSIGN_SEATS
		|| _mode === TRANSFER_SEATS;
};

export const canModeSelectDistanceSeats = () => {
	return _mode === SEAT_MANAGER
		|| _mode === REASSIGN_SEATS
		|| _mode === TRANSFER_SEATS;
};

export const canModeShowPrivateMessages = () => {
	return _userType === BO
		|| _userType === PROMOTER;
};

export const canModeShowOrderInfo = () => {
	return _userType === BO
		|| _userType === PROMOTER;
};

export const canModeShowBuyInstruction = () => {
	return _userType === BUYER;
};

export const canModeSellSoldOutPrices = () => {
	return _userType === BO
		|| _userType === PROMOTER;
};

export const canModeSellHiddenPrices = () => {
	return _userType === BO
		|| _userType === PROMOTER;
};

export const canModeSellDisabledPrices = () => {
	return false;
};
