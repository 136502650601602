import { html } from 'lit';
export const adaSvg = html `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="wheelchair" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M496.101 385.669l14.227 28.663c3.929 7.915.697 17.516-7.218 21.445l-65.465 32.886c-16.049 7.967-35.556 1.194-43.189-15.055L331.679 320H192c-15.925 0-29.426-11.71-31.679-27.475C126.433 55.308 128.38 70.044 128 64c0-36.358 30.318-65.635 67.052-63.929 33.271 1.545 60.048 28.905 60.925 62.201.868 32.933-23.152 60.423-54.608 65.039l4.67 32.69H336c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H215.182l4.572 32H352a32 32 0 0 1 28.962 18.392L438.477 396.8l36.178-18.349c7.915-3.929 17.517-.697 21.446 7.218zM311.358 352h-24.506c-7.788 54.204-54.528 96-110.852 96-61.757 0-112-50.243-112-112 0-41.505 22.694-77.809 56.324-97.156-3.712-25.965-6.844-47.86-9.488-66.333C45.956 198.464 0 261.963 0 336c0 97.047 78.953 176 176 176 71.87 0 133.806-43.308 161.11-105.192L311.358 352z"></path></svg>`;
export const checkSvg = html `<svg width="14" height="15" viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.7812 7.75C13.7812 4.03125 10.7188 0.96875 7 0.96875C3.25391 0.96875 0.21875 4.03125 0.21875 7.75C0.21875 11.4961 3.25391 14.5312 7 14.5312C10.7188 14.5312 13.7812 11.4961 13.7812 7.75ZM6.20703 11.3594C6.04297 11.5234 5.74219 11.5234 5.57812 11.3594L2.73438 8.51562C2.57031 8.35156 2.57031 8.05078 2.73438 7.88672L3.36328 7.28516C3.52734 7.09375 3.80078 7.09375 3.96484 7.28516L5.90625 9.19922L10.0078 5.09766C10.1719 4.90625 10.4453 4.90625 10.6094 5.09766L11.2383 5.69922C11.4023 5.86328 11.4023 6.16406 11.2383 6.32812L6.20703 11.3594Z"></path></svg>`;
export const companionSvg = html `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="companion" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 7"><path fill="currentColor" d="M2.98295 6.11364C2.47159 6.11364 2.03125 5.9929 1.66193 5.75142C1.29261 5.50994 1.00852 5.17732 0.809659 4.75355C0.610795 4.32978 0.511364 3.84564 0.511364 3.30114C0.511364 2.74716 0.613163 2.25829 0.816761 1.83452C1.02273 1.40838 1.30919 1.07576 1.67614 0.836648C2.04545 0.59517 2.47633 0.474432 2.96875 0.474432C3.35227 0.474432 3.69792 0.545455 4.00568 0.6875C4.31345 0.829545 4.56558 1.02841 4.76207 1.28409C4.95857 1.53977 5.08049 1.83807 5.12784 2.17898H4.28977C4.22585 1.9304 4.08381 1.71023 3.86364 1.51847C3.64583 1.32434 3.35227 1.22727 2.98295 1.22727C2.65625 1.22727 2.36979 1.3125 2.12358 1.48295C1.87973 1.65104 1.68916 1.88897 1.55185 2.19673C1.4169 2.50213 1.34943 2.8608 1.34943 3.27273C1.34943 3.69413 1.41572 4.06108 1.5483 4.37358C1.68324 4.68608 1.87263 4.92874 2.11648 5.10156C2.36269 5.27438 2.65152 5.3608 2.98295 5.3608C3.20076 5.3608 3.39844 5.32292 3.57599 5.24716C3.75355 5.1714 3.90388 5.0625 4.02699 4.92045C4.15009 4.77841 4.23769 4.60795 4.28977 4.40909H5.12784C5.08049 4.73106 4.9633 5.02107 4.77628 5.27912C4.59162 5.5348 4.34659 5.7384 4.04119 5.88991C3.73816 6.03906 3.38542 6.11364 2.98295 6.11364Z"></path></svg>`;
export const eyeSlashSvg = html `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="eye-slash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>`;
export const magnifierSvg = html `<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path fill="currentColor" d="M29.626 27.859L21.097 19.33C22.7491 17.2893 23.7438 14.6962 23.7438 11.8719C23.7438 5.32613 18.4177 0 11.8719 0C5.32607 0 0 5.32607 0 11.8719C0 18.4177 5.32613 23.7438 11.8719 23.7438C14.6962 23.7438 17.2893 22.7491 19.33 21.097L27.859 29.626C28.1027 29.8697 28.4226 29.9922 28.7425 29.9922C29.0625 29.9922 29.3824 29.8697 29.6261 29.626C30.1147 29.1374 30.1147 28.3476 29.626 27.859ZM11.8719 21.2444C6.70325 21.2444 2.49937 17.0405 2.49937 11.8719C2.49937 6.7032 6.70325 2.49931 11.8719 2.49931C17.0406 2.49931 21.2445 6.7032 21.2445 11.8719C21.2445 17.0405 17.0405 21.2444 11.8719 21.2444Z"></path></svg>`;
export const naSvg = html `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>`;
export const previewSvg = html `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-44 -44 600 615"><path fill="currentColor" d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>`;
export const trashSvg = html `<svg aria-hidden="true" focusable="false" width="13" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 15"><path fill="currentColor" d="M0.875 13.4375C0.875 14.1758 1.44922 14.75 2.1875 14.75H10.0625C10.7734 14.75 11.375 14.1758 11.375 13.4375V4.25H0.875V13.4375ZM8.3125 6.4375C8.3125 6.21875 8.50391 6 8.75 6C8.96875 6 9.1875 6.21875 9.1875 6.4375V12.5625C9.1875 12.8086 8.96875 13 8.75 13C8.50391 13 8.3125 12.8086 8.3125 12.5625V6.4375ZM5.6875 6.4375C5.6875 6.21875 5.87891 6 6.125 6C6.34375 6 6.5625 6.21875 6.5625 6.4375V12.5625C6.5625 12.8086 6.34375 13 6.125 13C5.87891 13 5.6875 12.8086 5.6875 12.5625V6.4375ZM3.0625 6.4375C3.0625 6.21875 3.25391 6 3.5 6C3.71875 6 3.9375 6.21875 3.9375 6.4375V12.5625C3.9375 12.8086 3.71875 13 3.5 13C3.25391 13 3.0625 12.8086 3.0625 12.5625V6.4375ZM11.8125 1.625H8.53125L8.25781 1.13281C8.14844 0.914062 7.92969 0.75 7.68359 0.75H4.53906C4.29297 0.75 4.07422 0.914062 3.96484 1.13281L3.71875 1.625H0.4375C0.191406 1.625 0 1.84375 0 2.0625V2.9375C0 3.18359 0.191406 3.375 0.4375 3.375H11.8125C12.0312 3.375 12.25 3.18359 12.25 2.9375V2.0625C12.25 1.84375 12.0312 1.625 11.8125 1.625Z"></path></svg>`;
export const triangleExclamationSvg = html `<svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M15.4473 11.8223L9.35352 1.23438C8.89648 0.447266 7.70312 0.421875 7.24609 1.23438L1.15234 11.8223C0.695312 12.6094 1.2793 13.625 2.21875 13.625H14.3809C15.3203 13.625 15.9043 12.6348 15.4473 11.8223ZM8.3125 9.61328C8.94727 9.61328 9.48047 10.1465 9.48047 10.7812C9.48047 11.4414 8.94727 11.9492 8.3125 11.9492C7.65234 11.9492 7.14453 11.4414 7.14453 10.7812C7.14453 10.1465 7.65234 9.61328 8.3125 9.61328ZM7.19531 5.42383C7.16992 5.24609 7.32227 5.09375 7.5 5.09375H9.09961C9.27734 5.09375 9.42969 5.24609 9.4043 5.42383L9.22656 8.87695C9.20117 9.05469 9.07422 9.15625 8.92188 9.15625H7.67773C7.52539 9.15625 7.39844 9.05469 7.37305 8.87695L7.19531 5.42383Z"></path></svg>`;
