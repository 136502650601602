import io from 'socket.io-client';
import { getMode, getUserType, isBoUser } from './mode';
import { holdSeat, lockSeat, sellSeat, unholdSeat, unlockSeat, unsellSeat } from './seats';
import { markDistanceSeats } from './distance-seats';

//Socket actions:
const LOAD = 'load';
const HOLD = 'hold';
const UNHOLD = 'unhold';
const SELECT = 'select';
const UNSELECT = 'unselect';
const LOCK = 'lock';
const UNLOCK = 'unlock';
const SELL = 'sell';
const UNSELL = 'unsell';

const SOCKETIO_HANDSHAKE_PATH = '/v3/ase3/bus';

let _host = {};
let _url = '';
let _progressSpinner = {};
let _eventId = 0;
let _resolveConnect = false;
let _socket;
let _sessionId = '';

export function createBus(host) {
	if (_socket) {
		return;
	}

	_host = host;
	_progressSpinner = host.progressSpinner;
	_url = host.url;

	const ioConfig = {
		path: SOCKETIO_HANDSHAKE_PATH,
		rememberUpgrade: true
	};

	if (_host.uniqueVisitorId) {
		ioConfig.auth = { uniqueVisitorId: _host.uniqueVisitorId };
		_sessionId = _host.uniqueVisitorId;
	}

	_socket = io(`${_url}`, ioConfig);

	_socket.io.on('error', console.error);
	_socket.io.on('reconnect_error', console.error);
	_socket.io.on('reconnect_failed', console.error);//Fired when couldn't reconnect within reconnectionAttempts.
	_socket.on('connect', handleConnect);
	_socket.on('disconnect', handleDisconnect);
	_socket.on('connect_error', handleConnectError);

	_socket.on(LOCK, handleLock);
	_socket.on(UNLOCK, handleUnlock);
	_socket.on(HOLD, handleHold);
	_socket.on(UNHOLD, handleUnhold);
	_socket.on(SELL, handleSell);
	_socket.on(UNSELL, handleUnsell);
}

export function setSessionId(sessionId) {
	_sessionId = sessionId;
}

export function setUrl(url) {
	_url = url;
}

function connect() {
	return new Promise((resolve) => {
		_resolveConnect = resolve;
		_socket.connect();
	});
}

// ********************************************* SOCKET HANDLERS *********************************************
function handleConnect() {
	if (_resolveConnect) {
		_resolveConnect();
	}

	_resolveConnect = false;
}

function handleDisconnect(reason) {
	if (reason === 'io server disconnect') {
		_socket.open();
	}
}

function handleConnectError(error) {
	console.log('Reconnect error', error);
	// alert(error.toString());
}

// *********************************************** SEAT HANDLERS ***********************************************
function handleLock(eventId, seatId) {
	if (eventId != _eventId) {
		return;
	}

	lockSeat(seatId);
	markDistanceSeats(seatId);
}

function handleUnlock(eventId, seatId) {
	if (eventId != _eventId) {
		return;
	}

	unlockSeat(seatId);
	markDistanceSeats(seatId);
}

function handleHold(eventId, seatId) {
	if (eventId != _eventId) {
		return;
	}

	holdSeat(seatId);
	markDistanceSeats(seatId);
}

function handleUnhold(eventId, seatId) {
	if (eventId != _eventId) {
		return;
	}

	unholdSeat(seatId);
	markDistanceSeats(seatId);
}

function handleSell(eventId, seatId) {
	if (eventId != _eventId) {
		return;
	}

	sellSeat(seatId);
	markDistanceSeats(seatId);
}

function handleUnsell(eventId, seatId) {
	if (eventId != _eventId) {
		return;
	}

	unsellSeat(seatId);
	markDistanceSeats(seatId);
}

// ************************************************* EMITS *************************************************
function emit(socketEvent, params) {
	params = params || {};
	params.eventId = _eventId;
	params.mode = getMode();
	params.userType = getUserType();

	if (_host.uniqueVisitorId) {
		params.uniqueVisitorId = _host.uniqueVisitorId;
	}

	return new Promise((resolve, reject) => {
		_socket.emit(socketEvent, params, (response) => {
			if (!response?.isSuccess) {
				if (response?.errorMessage) {
					alert(response.errorMessage);
				}

				console.log('Error response', response);
				return reject(new Error(response));
			}

			if (response.ttl > 0) {
				_host.sessionTimer.ttl = response.ttl;
			}

			resolve(response);
		});
	});
}

export async function emitLoadEvent(eventId, params = {}) {
	if (!_socket.connected) {
		await connect();
	}

	_eventId = eventId;
	return emit(LOAD, params);
}

export async function emitSelectSeats(seatIds, packageId, packageItemIndex, priceId) {
	if (seatIds.length < 1) {
		return console.error('Emit select seats error #1692868369. No seatIds.');
	}

	return await emit(SELECT, { seatIds, packageId, packageItemIndex, priceId });
}

export async function emitUnselectSeats(seatIds) {
	if (seatIds.length < 1) {
		return console.error('Emit unselect seats error #1692869105. No seatIds.');
	}

	return await emit(UNSELECT, { seatIds });
}

// ***************************************** POST ROUTINS *****************************************
const fetchData = async (method, url, body = '', headers = {}) => {
	let ret;

	headers['Content-Type'] = 'application/json';
	if (isBoUser()) {
		headers['Session-Type'] = 'BO';
	}
	_progressSpinner.isHidden = false;

	// Default options are marked with *
	const request = {
		method, // *GET, POST, PUT, DELETE, etc.
		headers,
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	};

	if (body) {
		request.body = body; // body data type must match "Content-Type" header
	}

	try {
		const response = await fetch(_url + url, request);
		ret = await response.json();
	} catch (error) {
		console.error(error);
		ret = { success: false, info: 'Server error #1683118464' };
	} finally {
		setTimeout(() => {
			_progressSpinner.isHidden = true;
		}, 500);
	}

	return ret;
};

export const getData = async (url = '', params = {}) => {
	params.userType = getUserType();
	params.mode = getMode();

	if (_sessionId) {
		params.PHPSESSID = _sessionId;
	}

	const queryString = new URLSearchParams(params).toString();

	return await fetchData('GET', `${url}?${queryString}`);
};

export const postData = async (url = '', params = {}) => {
	return await fetchData('POST', url, JSON.stringify(params));
};

export const putData = async (url = '', params = {}, headers = {}) => {
	return await fetchData('PUT', url, JSON.stringify(params), headers);
};

export const fetchCallback = (result) => {
	if (result.success) {
		return result;
	}

	if (result.info) {
		alert(result.info);
	}

	if (result.errors && result.errors.reason) {
		alert(result.errors.reason);
	}

	if (result.msg) {
		alert(result.msg);
	}
};

const normalizeSrc = (src) => {
	if (src && src[0] != '/' && src[0] != 'h') {
		return '/' + src;
	}

	return src;
};

export const getFileJson = async (src) => {
	const response = await fetch(_url + normalizeSrc(src));

	if (!response.ok) {
		const errorMessage = `Load map error #1660716604. HTTP status: ${response.status}`;
		// alert(errorMessage);
		throw new Error(errorMessage);
	}

	return await response.json();
};

export const simpleFetch = async (url) => {
	return await fetch(_url + url);
};
