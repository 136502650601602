var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getPriceLevels } from '../../price-levels';
export const PricingLegendTag = 'pricing-legend';
let PricingLegend = class PricingLegend extends LitElement {
    constructor() {
        super(...arguments);
        this.priceLevels = [];
        this.eventId = 0;
        this.isOpen = false;
        this.isMobile = false;
        this.isHidden = false;
        this.handleTitleClick = () => {
            this.isOpen = !this.isOpen;
        };
    }
    render() {
        return html `
			<div class="title" @click=${this.handleTitleClick}>
				${this.isMobile ? 'PRICING' : 'PRICING LEGEND'}
			</div>

			${this.priceLevels.map((item) => html `
				<div class="item">
					<div class="item-name">
						<div class="item-color" style="background-color: ${item.color}"></div>
						${item.name}
					</div>
					<div class="item-price">
						${item.priceRangeText}
					</div>
				</div>
			`)}

			<div class="item">
				<div class="item-name">
					<div class="item-color" style="border: 2px solid #b7b7b7"></div>
					Not available
				</div>
			</div>
		`;
    }
    willUpdate(changedProperties) {
        if (changedProperties.has('eventId')) {
            this.priceLevels = getPriceLevels()
                .sort((priceLevel1, priceLevel2) => priceLevel1.name.localeCompare(priceLevel2.name));
        }
    }
};
PricingLegend.styles = css `
		:host {
			margin: 21px 0;
			padding: 5px 0;
			overflow: hidden;
			display: block;
			transition: max-height .3s ease-in-out;
			border-radius: 3px;
			border: solid 1px var(--color-grey-25);
			background-color: var(--color-white);
			max-height: 51px;
			box-sizing: border-box;
			font-family: 'Poppins', sans-serif;
			line-height: 1.5;
		}

		.title {
			position: relative;
			margin: 10px;
			font-size: 16px;
			font-weight: 500;
			color: var(--color-text-grey);
			letter-spacing: 1px;
			text-align: center;
			cursor: pointer;
		}

		.title:before {
			content: '';
			width: 8px;
			height: 8px;
			border-top: 2px solid var(--color-accent);
			border-right: 2px solid var(--color-accent);
			display: block;
			left: 291px;
			position: absolute;
			top: 5px;
			transform: rotate(135deg);
			transition: all 0.3s ease-in-out;
		}

		.item {
			display: flex;
			justify-content: space-between;
			margin: 10px;
		}

		.item-name {
			display: flex;
			align-items: flex-start;
			font-size: 14px;
			font-weight: 500;
			color: var(--color-text-grey);
		}

		.item-color {
			display: inline-block;
			box-sizing: border-box;
			min-width: 20px;
			max-width: 20px;
			min-height: 20px;
			max-height: 20px;
			border-radius: 50%;
			margin-right: 10px;
		}

		.item-price {
			font-size: 14px;
			letter-spacing: 0.4px;
			text-align: right;
			color: var(--color-text-grey);
			position: relative;
			top: 1px;
			min-width: 100px;
			padding-left: 10px;
		}

		:host([ishidden]) {
			display: none;
		}

		:host([isopen]) {
			max-height: 1500px!important;
		}

		:host([isopen]) .title:before {
			top: 9px;
			transform: rotate(-45deg);
		}

		:host([ismobile]) .item {
			background-color: var(--color-white);
		}

		:host([ismobile]) .title {
			color: var(--color-white);
		}
	`;
__decorate([
    property({ type: Number })
], PricingLegend.prototype, "eventId", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], PricingLegend.prototype, "isOpen", void 0);
__decorate([
    property({ type: Boolean })
], PricingLegend.prototype, "isMobile", void 0);
__decorate([
    property({ type: Boolean })
], PricingLegend.prototype, "isHidden", void 0);
PricingLegend = __decorate([
    customElement(PricingLegendTag)
], PricingLegend);
export { PricingLegend };
