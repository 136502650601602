import { getDevSeatStateNotes } from './seat-states';
import { canModeShowPrivateMessages } from './mode';
const _publicMessages = new Map();
const _privateMessages = new Map();
export function initMessages(publicMessages, privateMessages) {
    _publicMessages.clear();
    _privateMessages.clear();
    for (const seatId in publicMessages) {
        _publicMessages.set(Number(seatId), publicMessages[seatId]);
    }
    for (const seatId in privateMessages) {
        _privateMessages.set(Number(seatId), privateMessages[seatId]);
    }
}
export function setPublicMessages(seatIds, message) {
    seatIds.forEach(seatId => {
        if (message.length > 0) {
            _publicMessages.set(seatId, message);
        }
        else {
            _publicMessages.delete(seatId);
        }
    });
}
export function setPrivateMessages(seatIds, message) {
    seatIds.forEach(seatId => {
        if (message.length > 0) {
            _privateMessages.set(seatId, message);
        }
        else {
            _privateMessages.delete(seatId);
        }
    });
}
export function getPublicMessages(seatIds) {
    return seatIds.map(seatId => _publicMessages.get(seatId));
}
export function getPrivateMessages(seatIds) {
    return seatIds.map(seatId => _privateMessages.get(seatId));
}
export function getPublicMessage(seatId) {
    return _publicMessages.get(seatId) || '';
}
export function getPrivateMessage(seatId) {
    if (!canModeShowPrivateMessages()) {
        return '';
    }
    return _privateMessages.get(seatId) || '';
}
export function getDevMessage(seatId) {
    if (process.env.NODE_ENV !== 'development') {
        return '';
    }
    return getDevSeatStateNotes(seatId) || '';
}
