var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getMapSeat, getMapSeatInfo } from '../map-seats';
import { getPriceLevelPriceRangeText } from '../prices';
import { getDevMessage, getPrivateMessage, getPublicMessage } from '../messages';
import { isSeatStateDistanced, isSeatStateHold, isSeatStateLock, isSeatStateNa, isSeatStateSold } from '../seat-states';
import { isBoUser, isBuyerUser, isSellMode } from '../mode';
import { eyeSlashSvg, adaSvg } from './icons';
import { headerStyles } from './styles';
import { getSuborderSeatIds } from '../suborder-seats';
import { getSection } from '../sections';
export const SeatHintTag = 'seat-hint';
let SeatHint = class SeatHint extends LitElement {
    constructor() {
        super();
        this.isMobile = true;
        this.seatId = 0;
        this.left = -999;
        this.top = -999;
        this._sectionName = '';
        this._priceLevelName = '';
        this._seatInfo = '';
        this._color = '';
        this._privateMessage = '';
        this._publicMessage = '';
        this._extraMessage = '';
        this._price = '';
        this._state = '';
        this._image = '';
        this._isAccessible = false;
        this._isCompanion = false;
        this._isRestricted = false;
        this._isWheelchair = false;
        this.addEventListener('mouseover', this.handleMouseOver);
    }
    render() {
        return html `
			<div class="header">${this._seatInfo}</div>
			<div class="section" ?hidden=${this._sectionName === this._priceLevelName}>${this._sectionName}</div>
			<div class="content">
				<div class="price-level"><div class="seat" style="background-color:${this._color}"></div><div class="price-level-name">${this._priceLevelName}</div></div>
				<div class="seat-types" ?hidden=${!this._isAccessible && !this._isRestricted && !this._isCompanion && !this._isWheelchair}>
					<div class="seat-type" ?hidden=${!this._isAccessible}>
						<div class="svg-icon">${adaSvg}</div>
						<div>Accessible seat</div>
					</div>
					<div class="seat-type" ?hidden=${!this._isWheelchair}>
						<div class="svg-icon">${adaSvg}</div>
						<div>Wheelchair space</div>
					</div>
					<div class="seat-type" ?hidden=${!this._isCompanion}>
						<div class="svg-icon">${adaSvg}</div>
						<div>Companion Seat</div>
					</div>
					<div class="seat-type" ?hidden=${!this._isRestricted}>
						<div class="svg-icon">${eyeSlashSvg}</div>
						<div>Limited view</div>
					</div>
				</div>
				<div class="line"></div>

				<div class="seat-details">
					<div class="price" ?hidden=${!this._price}>${this._price}</div>
					<div class="public-message" ?hidden=${!this._publicMessage}>${this._publicMessage}</div>
					<div class="private-message" ?hidden=${!this._privateMessage}><div class="title">INTERNAL MEMO</div>${this._privateMessage}</div>
					<div class="extra-message" ?hidden=${!this._extraMessage}>${this._extraMessage}</div>
					<div class="image" ?hidden=${!this._image}><img src=${this._image} alt="Seat photo" /></div>
				</div>

				<div class="seat-state" ?hidden=${!this._state}>${this._state}</div>
			</div>
		`;
    }
    willUpdate() {
        if (this.seatId === 0) {
            return;
        }
        const seat = getMapSeat(this.seatId);
        const seatInfo = getMapSeatInfo(this.seatId);
        const section = getSection(seat.sectionId);
        this._color = seatInfo.color;
        this._sectionName = section.name;
        this._priceLevelName = seatInfo.priceLevelName;
        this._seatInfo = seatInfo.seatInfo;
        this._isAccessible = seat.isAccessible;
        this._isCompanion = seat.isCompanion;
        this._isRestricted = seat.isRestricted;
        this._isWheelchair = seat.isWheelchair;
        this._price = getPriceLevelPriceRangeText(seat.priceLevelId);
        this._state = this.getState(this.seatId);
        this._image = seat.previewPath;
        this._publicMessage = getPublicMessage(this.seatId);
        this._privateMessage = getPrivateMessage(this.seatId);
        this._extraMessage = getDevMessage(this.seatId);
    }
    updated() {
        if (this.seatId > 0) {
            let left = this.left - this.offsetWidth / 2;
            let top = this.top - this.offsetHeight - 20;
            if (left < 10) {
                left = 10;
            }
            if (top < 60) {
                top = this.top + 20;
            }
            this.style.left = left + 'px';
            this.style.top = top + 'px';
            return;
        }
        this.style.left = '-999px';
        this.style.top = '-999px';
    }
    handleMouseOver() {
        this.seatId = 0;
    }
    getState(seatId) {
        const subOrderSeatIds = getSuborderSeatIds();
        let state = '';
        if (subOrderSeatIds.includes(seatId)) {
            return 'Current seats';
        }
        if (isSeatStateNa(seatId)) {
            state = 'Not available';
        }
        if (isSeatStateHold(seatId)) {
            state = 'On hold';
        }
        if (isSeatStateSold(seatId)) {
            state = 'Sold';
            if (!isSellMode() || isBoUser()) {
                state += ' (Click for details)';
            }
        }
        if (state != '' && isBuyerUser()) {
            state = 'Not available';
        }
        if (state != '') {
            return state;
        }
        if (isSeatStateLock(seatId)) {
            return 'Selected by another person';
        }
        if (isSeatStateDistanced(seatId)) {
            return 'Unavailable due to social distancing';
        }
        return '';
    }
};
SeatHint.styles = [
    headerStyles,
    css `
		:host {
			font-family: Poppins!important;
			position: absolute;
			z-index: 100;
			background: var(--color-main-background);
			box-shadow: 0px 1px 0.9px 0.1px rgba(0, 0, 0, 0.24), 0 0 2px 0px rgba(0, 0, 0, 0.16);
			border-radius: 5px;
			display: block;
			max-width: 278px;
			transition: opacity 0.1s ease-in-out;
		}

		:host([ismobile]) {
			display: none;
		}

		:host([seatid="0"]) {
			opacity: 0;
  			transition: opacity 0.1s ease-in-out;
		}

		.section {
			height: 28px;
			line-height: 28px;
			background-color: #f2f2f2;
			color: #6F7287;
			text-align: center;
			font-size: 12px;
			font-weight: 600;
			letter-spacing: 1.20px;
			text-transform: uppercase;
		}

		.content {
			padding: 8px 16px 12px;
			min-width: 210px;
		}

		.price-level {
			display: flex;
			align-items: center;
		}

		.price-level-name {
			color: #6F7287;
			padding-left: 8px;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 600;
		}

		.seat{
			width: 16px;
			height: 16px;
			border-radius:50%;
			background-color: var(--color-purple-6);
		}

		.seat-types {
        	padding: 2px 0 0 0;
    	}

		.svg-icon {
			color: #F53F7B;
			display: inline-block;
			mask-repeat: no-repeat;
			mask-position: center;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-position: center;
			margin-right: 3px;
			width: 18px;
			height: 18px;
		}

		.seat-type {
			white-space: nowrap;
			padding-top: 12px;
			font-size: 12px;
			line-height: 12px;
			font-weight: 500;
			letter-spacing: normal;
			color: var(--color-text-grey);
			text-transform: uppercase;
		}

		.seat-type svg {
			height: 1em;
			font-size: 16px;
			margin-right: 6px
		}

		.seat-type div {
			display: inline-block;
			vertical-align: middle;
		}

		.line {
			height: 1px;
			opacity: 0.4;
			background-color: var(--color-grey-9);
			margin: 11px -15px 0 -15px;
		}

		.seat-details {
			padding-top: 11px;
		}

		.price {
			white-space: nowrap;
			font-size: 18px;
			font-weight: 600;
			line-height: 16px;
			letter-spacing: normal;
			color: #215BB1;
		}

		.seat-state {
			font-size: 12px;
			font-weight: 600;
			line-height: 1.5;
			letter-spacing: 0.4px;
			color: #F53F7B;
			text-align: center;
			padding: 8px 0;
			background-color: #FFF4F7;
			border-radius: 5px;
			margin-top: 8px;
		}

		.public-message, .private-message, .extra-message {
			color: #6F7287;
			font-size: 13px;
			border-radius: 5px;
			margin: 8px 0 0;
			padding: 10px 14px;
		}

		.public-message {
			background-color: rgba(46, 102, 196, 0.06);
		}

		.private-message {
			background-color: rgba(132, 131, 11, 0.06);
		}

		.private-message .title{
			font-size: 9px;
			font-weight: 600;
		}

		.note {
			padding-top: 11px;
			font-size: 12px;
			font-weight: 500;
			line-height: 1.33;
			letter-spacing: normal;
			max-width: 230px;
			color: var(--color-text-grey);
		}

		.image {
			padding-top: 13px;
			margin: 0 -5px;
			min-height: 150px;
		}

		.image img {
			max-height: 135px;
		}

		.arrow {
			position: absolute;
			width: 100%;
			height: 15px;
			overflow: hidden;
		}

		.arrow:before {
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			transform: rotate(45deg);
			background-color: var(--color-main-background);
			box-shadow: 0px 1px 0.9px 0.1px rgba(0, 0, 0, 0.24), 0 0 2px 0px rgba(0, 0, 0, 0.16);
			position: absolute;
			left: calc(50% - 10px);
			top: -10px;
		}
	`
];
__decorate([
    property({ type: Boolean })
], SeatHint.prototype, "isMobile", void 0);
__decorate([
    property({ type: Number, reflect: true })
], SeatHint.prototype, "seatId", void 0);
__decorate([
    property({ type: Number })
], SeatHint.prototype, "left", void 0);
__decorate([
    property({ type: Number })
], SeatHint.prototype, "top", void 0);
SeatHint = __decorate([
    customElement(SeatHintTag)
], SeatHint);
export { SeatHint };
