import { getBlock } from './blocks';
import { drawDirtySeat, seatRadius } from './canvas/canvas-ctx';
import { getPriceLevel } from './price-levels';
import { getSection } from './sections';

/**
 * Array of map seat coordinates
 * @type {{id: Number, x: Number, y: Number}[]}
 */
let currentMapSeatCoordinates = [];

/**
 * Array of map seats
 * @type {{id: Number, priceLevelId: Number, blockId: Number, sectionId: Number,  x: Number, y: Number, isAccessible: Boolean, accessibleQuestion: String, isRestricted: Boolean, priceLevelName: String, rowLabel: String, seatLabel: String, color: String}[]}
 */
let currentMapSeats = [];

export function initMapSeats(seats) {
	const priceLevelNames = {};
	const blocks = {};
	const seatIds = [];
	const duplicateSeats = [];

	currentMapSeats = [];

	seats.forEach(seat => {
		if (seatIds.includes(seat.id)) {
			duplicateSeats.push(seat);
		}

		let seatInfo = `Table ${seat.rowLabel}, Seat ${seat.seatLabel}`;
		seatIds.push(seat.id);

		if (!priceLevelNames[seat.priceLevelId]) {
			priceLevelNames[seat.priceLevelId] = getPriceLevel(seat.priceLevelId).name;
		}

		if (seat.blockId && !blocks[seat.blockId]) {
			blocks[seat.blockId] = getBlock(seat.blockId);
		}

		if (seat.blockId) {
			const block = blocks[seat.blockId];
			let rowTitle = block.rowTitle;

			if (rowTitle && rowTitle[rowTitle.length - 1] == ':') {
				rowTitle = rowTitle.substring(0, rowTitle.length - 1);
			}

			seatInfo = `${rowTitle} ${block.rowPrefix + seat.rowLabel}, Seat ${block.seatPrefix + seat.seatLabel}`;
		}

		currentMapSeats.push({
			id: seat.id,
			priceLevelId: seat.priceLevelId,
			blockId: seat.blockId,
			sectionId: seat.sectionId,
			x: seat.x,
			y: seat.y,
			priceLevelName: priceLevelNames[seat.priceLevelId],
			seatInfo,
			rowLabel: seat.rowLabel,
			seatLabel: seat.seatLabel,
			color: seat.color,
			previewPath: seat.previewPath,
			isAccessible: seat.isAccessible,
			isWheelchair: seat.isWheelchair,
			accessibleQuestion: seat.accessibleQuestion,
			isRestricted: seat.isRestricted,
			isCompanion: seat.isCompanion
		});
	});

	currentMapSeatCoordinates = currentMapSeats.map(seat => ({
		id: seat.id,
		x: seat.x,
		y: seat.y
	}));

	if (duplicateSeats.length) {
		const duplicatedList = duplicateSeats.map(seat => seat.id)
			.join(', ');
		alert(`N's error #1678435665. Duplicate seats found: ${duplicatedList}`);
		console.log('Duplicate seats', duplicateSeats);
	}
}

export function drawMapSeats() {
	currentMapSeats.forEach(seat => {
		drawDirtySeat(seat);
	});
}

/**
 *
 * @param {number} seatId Seat Id
 * @returns {Object} MapSeat object { id, blockId, sectionId, x, y, rowLabel, seatLabel, color }
 */
export function getMapSeat(seatId) {
	return currentMapSeats.find(seat => seat.id == seatId);
}

export function getMapSeatInRange(x, y, range = seatRadius) {
	return currentMapSeatCoordinates.find((seat) => {
		return Math.sqrt(Math.pow(seat.x - x, 2) + Math.pow(seat.y - y, 2)) < range;
	});
}

export function getLabelRangeMapSeats(seatId1, seatId2) {
	const seat1 = getMapSeat(seatId1);
	const seat2 = getMapSeat(seatId2);

	let x1 = seat1.x;
	let x2 = seat2.x;

	if (x1 > x2) {
		[x1, x2] = [x2, x1];
	}

	if (seat1.priceLevelName !== seat2.priceLevelName) {
		return [];
	}

	let rowLabel1 = seat1.rowLabel;
	let seatLabel1 = seat1.seatLabel;
	let rowLabel2 = seat2.rowLabel;
	let seatLabel2 = seat2.seatLabel;

	if (rowLabel1 > rowLabel2) {
		[rowLabel1, rowLabel2] = [rowLabel2, rowLabel1];
	}

	if (seatLabel1 * 1 > seatLabel2 * 1) {
		[seatLabel1, seatLabel2] = [seatLabel2, seatLabel1];
	}

	const rowSeats = currentMapSeats.filter(seat => seat.priceLevelId == seat1.priceLevelId && seat.priceLevelName == seat1.priceLevelName && seat.rowLabel >= rowLabel1 && seat.rowLabel <= rowLabel2);

	return rowSeats.filter(seat => {
		if (seat.rowLabel == rowLabel1 && seat.seatLabel * 1 < seatLabel1 * 1) {
			return false;
		}

		if (seat.rowLabel == rowLabel2 && seat.seatLabel * 1 > seatLabel2 * 1) {
			return false;
		}

		if (seat.x < x1 || seat.x > x2) {
			return false;
		}

		return true;
	});
}

export function getAreaRangeSeats(x1, y1, x2, y2) {
	if (x1 > x2) {
		[x1, x2] = [x2, x1];
	}

	if (y1 > y2) {
		[y1, y2] = [y2, y1];
	}

	return currentMapSeatCoordinates.filter(seat => {
		return (seat.x >= x1 && seat.x <= x2 && seat.y >= y1 && seat.y <= y2);
	});
}

export function getPriceLevelSeatIds(priceLevelId) {
	return currentMapSeats
		.filter(seat => seat.priceLevelId == priceLevelId)
		.map(seat => seat.id);
}

export function getPriceLevelText(priceLevelId, sectionId) {
	const section = getSection(sectionId);
	const priceLevel = getPriceLevel(priceLevelId);
	let priceLevelText = priceLevel.name;

	if (priceLevel.name !== section.name && section.name !== '') {
		priceLevelText = `${priceLevel.name} (${section.name})`;
	}

	return priceLevelText;
}

export function getMapSeatInfo(seatId) {
	const mapSeat = getMapSeat(seatId);
	const priceLevel = getPriceLevel(mapSeat.priceLevelId);
	return {
		id: mapSeat.id,
		priceLevelId: mapSeat.priceLevelId,
		priceLevelName: priceLevel.name,
		priceLevelText: getPriceLevelText(mapSeat.priceLevelId, mapSeat.sectionId),

		sectionId: mapSeat.sectionId,

		color: mapSeat.color,
		seatInfo: mapSeat.seatInfo
	};
}
